import configuration from '../../../api-config.json';
import { POST, GET, GETLIST, PUT, DELETE } from '../../../shared/services/apiRequests';
import { MerchantModel } from '../models/MerchantModel';
import { CreateResponseModel } from '../../../shared/models/CreateResponseModel';
import { CreateMerchantModel } from '../models/CreateMerchantModel';
import { UpdateMerchantModel } from '../models/UpdateMerchantModel';
import { UpdateResponseModel } from '../../../shared/models/UpdateResponseModel';
import { SelectListItemModel } from '../../../shared/models/SelectListItemModel';

const entity = 'merchants';
const baseUrl = `${configuration.API_URL}/${entity}`;
const routeVersion1 = '/v1';

export const createMerchant = async (data: CreateMerchantModel, handleResult: Function): Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1;
  const successMessage = `${data?.name} was created successfully!`;
  await POST<CreateMerchantModel>(data, url, successMessage, true, handleResult);
}

export const getMerchant = async (id: string | undefined): Promise<UpdateMerchantModel | string> => {
  const url = baseUrl + routeVersion1+ '/' +  id;
  return await GET<UpdateMerchantModel>(url, true);
}

export const getMerchants = async (): Promise<Array<MerchantModel> | string> => {
  const url = baseUrl + routeVersion1 + '/list';
  return await GETLIST<Array<MerchantModel>>(url, true);
}

export const updateMerchant = async (data: UpdateMerchantModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1;
  const successMessage = `${data?.name} was updated successfully!`;
  await PUT<UpdateMerchantModel>(data, url, successMessage, true, handleResult);
}

export const deleteMerchant = async (data: MerchantModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/' +  data.merchantId;
  const successMessage = `${data.name} was deleted successfully!`;
  await DELETE(url, successMessage, true, handleResult);
}

export const getMerchantSelectList = async (): Promise<Array<SelectListItemModel> | string> => {
  const url = baseUrl + routeVersion1 + '/select-list' ;
  return await GETLIST<Array<SelectListItemModel>>(url, true);
}

export const getMerchantTypes = async (): Promise<Array<SelectListItemModel> | string> => {
  const url = baseUrl + routeVersion1 + '/types';
  return await GETLIST<Array<SelectListItemModel>>(url, true);
}
