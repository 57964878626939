import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import '../../assets/css/form.css';
import '../../assets/css/auth.css';
import logo from '../../assets/images/logo-large.png';
import { createSession } from '../services/requests';
import { SignInModel } from '../models/SignInModel';
import { CreateResponseModel } from '../../shared/models/CreateResponseModel';
import ActionAlert, { State } from '../../shared/components/ActionAlert/ActionAlert';

export default function SignIn() {

  type LocationProps = {
    state: {
      from: Location;
    };
  };
  const [searchParams] = useSearchParams();
  const isLoggedOut = searchParams.get('loggedout');
  const isSessionExpired = searchParams.get('expired');

  const navigate = useNavigate();
  const location = useLocation() as unknown as LocationProps;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [credentials, setCredentials] = useState<SignInModel>(new SignInModel());
  const [hasError, setHasError] = useState<boolean>(false);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
    autoHideDuration: 0
  });

  const from = location.state?.from?.pathname || '/';

  const signIn = async () => {
    const createResult = (result: CreateResponseModel) => {
      if(result.errors.length > 0) {
        setHasError(true);
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setHasError(false);
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
      }
      setIsSubmitting(false);
    }
    await createSession(credentials, createResult);
  }

  const handleUpdateValue = (name, value) => {
    setCredentials(x => ({...x, [name]: value}));
  }

  const handleInputChange = (e) => {
    handleUpdateValue(e.target.name, e.target.value);
  }

  useEffect(() => {
    if(isSessionExpired) {
      setHasError(true);
      setActionAlertState({ 
        open: true, 
        messages: [ 'Your session has expired, sign in to continue' ], 
        severity: 'error',
        autoHideDuration: 4000
      });
    }
    if(isLoggedOut) {
      setHasError(true);
      setActionAlertState({ 
        open: true, 
        messages: [ 'You have successfully logged out of Ledgers' ], 
        severity: 'info',
        autoHideDuration: 4000
      });
      window.history.replaceState(null, 'Ledgers', '/sign-in')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await signIn();
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
    if(!hasError) {
      navigate(from, { replace: true });
    }
  };

  return (
    <React.Fragment>
      <div className='auth-container'>
        <div className='auth-action-container'>
          <div className='auth-action-section'>
            <div className='auth-action-logo'>
              <img src={logo} alt='' />
              <span>Ledgers</span>
            </div>
            <div className='auth-action-title'>
              <span>Simple, Opinionated Money Tracking</span>
            </div>
            <div className='auth-action-subtitle'>
              <span>Sign in to continue to Ledgers</span>
            </div>
            <div className='form-container'>
              <form onSubmit={handleSubmit}>
                <div className='form-input-container'>
                  <input 
                    key='username' 
                    name='username' 
                    type='text' 
                    placeholder='Username' 
                    autoComplete='off' 
                    autoCapitalize='off'
                    onChange={handleInputChange} 
                    required 
                  />
                </div>
                <div className='form-input-container'>
                  <input 
                    key='password' 
                    name='password' 
                    type='password' 
                    placeholder='Password' 
                    autoComplete='off' 
                    onChange={handleInputChange} 
                    required 
                  />
                </div>
                <div className='forgot-password'>
                  <a href='/forgot-password'>Forgot Password?</a>
                </div>
                <div className='form-buttons-container'>
                  <Button 
                    className='auth-action-button' 
                    variant='outlined' 
                    color='success' 
                    size='medium' 
                    type='submit'>
                    {!isSubmitting && (
                      <span>Sign In</span>
                    )}
                    {isSubmitting && (
                      <CircularProgress 
                        color='success' 
                        size={24}
                      />
                    )}
                  </Button>
                </div>
              </form>
            </div>
            <div className='sign-up'>
              Don't have an account?
              <NavLink to='/request-invite' >
                &nbsp;Request Invite
              </NavLink> 
            </div>
          </div>
        </div>
        <div className='auth-background-container'>
        </div>
      </div>
      <ActionAlert 
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
        autoHideDuration={actionAlertState.autoHideDuration}
      />
    </React.Fragment>
  )
}
