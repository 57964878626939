import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import '../../../assets/css/form.css';
import '../../../assets/css/auth.css';
import logo from '../../../assets/images/logo-large.png';
import { validateInviteRequestToken } from '../services/requests';
import { ValidateTokenModel } from '../../../auth/models/ValidateTokenModel';
import { CreateResponseModel } from '../../../shared/models/CreateResponseModel';
import ActionAlert, { State } from '../../../shared/components/ActionAlert/ActionAlert';

export default function ValidateInvite() {

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
    autoHideDuration: 0
  });

  const validate = async (tokenValidation: ValidateTokenModel) => {
    const createResult = (result: CreateResponseModel) => {
      if(result.errors.length > 0) {
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        navigate(`/sign-up?token=${token}`);
      }
      setIsSubmitting(false);
    }
    await validateInviteRequestToken(tokenValidation, createResult);
  }

  useEffect(() => {
    setIsSubmitting(true);
    if(token === undefined || token === null) {
      setActionAlertState({ 
        open: true, 
        messages: [ 'Your token is invalid or expired' ], 
        severity: 'error',
        autoHideDuration: 4000
      });
    } else {
      let tokenValidation = new ValidateTokenModel();
      tokenValidation.token = token;
      validate(tokenValidation);
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
    navigate('/request-invite');
  };

  return (
    <React.Fragment>
      <div className='auth-container'>
        <div className='auth-action-container'>
          <div className='auth-action-section'>
            <div className='auth-action-logo'>
              <img src={logo} alt='' />
              <span>Ledgers</span>
            </div>
            <div className='auth-action-title'>
              <span>Validating New User Invitation</span>
            </div>
            <div className='auth-action-subtitle'>
              <span></span>
            </div>
            <div className='progress-container'>
              {isSubmitting && (
                <CircularProgress 
                  color='success' 
                  size={100}
                />
              )}
            </div>
          </div>
        </div>
        <div className='auth-background-container'>
        </div>
      </div>
      <ActionAlert 
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
        autoHideDuration={actionAlertState.autoHideDuration}
      />
    </React.Fragment>
  )
}