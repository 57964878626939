import React, { memo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import '../../../assets/css/dialog.css';
import { CopyBudgetModel, MonthModel, YearModel } from '../models/CopyBudgetModel';

const CopyBudgetDialog = ({ 
  budgetId,
  budgetName,
  openDialog,
  handleCancel,
  handleCopy
}) => {

  const [budget, setBudget] = useState<CopyBudgetModel>(new CopyBudgetModel());

  const months: Array<MonthModel> = [
    { monthId: 1, month: 'January' },
    { monthId: 2, month: 'February' },
    { monthId: 3, month: 'March' },
    { monthId: 4, month: 'April' },
    { monthId: 5, month: 'May' },
    { monthId: 6, month: 'June' },
    { monthId: 7, month: 'July' },
    { monthId: 8, month: 'August' },
    { monthId: 9, month: 'September' },
    { monthId: 10, month: 'October' },
    { monthId: 11, month: 'November' },
    { monthId: 12, month: 'December' }
  ];

  const years: Array<YearModel> = [
    { yearId: 1, year: 2021 },
    { yearId: 2, year: 2022 },
    { yearId: 3, year: 2023 },
    { yearId: 4, year: 2024 },
    { yearId: 5, year: 2025 },
    { yearId: 6, year: 2026 }
  ];

  const handleUpdateValue = (name, value) => {
    setBudget(x => ({...x, [name]: value}));
  }

  const handleInputChange = (e) => {
    handleUpdateValue(e.target.name, e.target.value);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    budget.budgetId = budgetId
    handleCopy(budget);
  };

  return (
    <React.Fragment>
      <Dialog open={openDialog}>
        <DialogTitle>
        </DialogTitle>
        <DialogContent>
          Create New Budget From {budgetName}
        </DialogContent>
        <div className='dialog-form-container'>
          <form onSubmit={handleSubmit}>
            <div className='dialog-form-input-container'>
              <select 
                id='monthId' 
                name='monthId' 
                value={budget.monthId} 
                onChange={handleInputChange}
                required
              >
                <option key='0' value={''} disabled={true}>Select Month...</option>
                {months.map(x => <option key={x.monthId} value={x.monthId}>{x.month}</option>)}
              </select>
            </div>
            <div className='dialog-form-input-container'>
              <select 
                id='year' 
                name='year' 
                value={budget.year} 
                onChange={handleInputChange}
                required
              >
                <option key='0' value={''} disabled={true}>Select Year...</option>
                {years.map(x => <option key={x.yearId} value={x.year}>{x.year}</option>)}
              </select>
            </div>
            <div className='dialog-form-input-container'>
              <input 
                key='name' 
                name='name' 
                type='text' 
                placeholder='Budget Name' 
                autoComplete='off' 
                onChange={handleInputChange} 
                required 
                minLength={3} 
                maxLength={50} 
              />
            </div>
            <DialogActions>
              <div className="dialog-action-container">
                <Button 
                  className='mui-button'
                  variant='outlined' 
                  color='error' 
                  size='medium'
                  onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  className='mui-button'
                  variant='outlined' 
                  color='success' 
                  size='medium'
                  type='submit'>
                  Copy
                </Button>
              </div>
            </DialogActions>
        </form>
      </div>
      </Dialog>
    </React.Fragment>
  );
};

export default memo(CopyBudgetDialog);