import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import "../../../assets/css/list.css";
import "./overview.css";
import {
  getPrimaryAccountBalance,
  getCreditTotals,
} from "../services/requests";
import { PrimaryAccountBalanceModel } from "../models/PrimaryAccountBalanceModel";
import { CreditTotalsModel } from "../models/CreditTotalsModel";
import Desktop from "./Desktop/Desktop";
import Mobile from "./Mobile/Mobile";

const Overview = ({ handleResponse }) => {
  const navigate = useNavigate();
  const [isPrimaryAccountDataLoading, setIsPrimaryAccountDataLoading] =
    useState(true);
  const [isCreditTotalsDataLoading, setIsCreditTotalsDataLoading] =
    useState(true);
  const [primaryAccountBalance, setPrimaryAccountBalance] =
    useState<PrimaryAccountBalanceModel>(new PrimaryAccountBalanceModel());
  const [creditTotals, setCreditTotals] = useState<CreditTotalsModel>(
    new CreditTotalsModel()
  );

  const getPrimaryAccountData = async () => {
    const data = await getPrimaryAccountBalance();
    await handleResponse(data, setPrimaryAccountBalance);
    setIsPrimaryAccountDataLoading(false);
  };

  const getCreditTotalsData = async () => {
    const data = await getCreditTotals();
    await handleResponse(data, setCreditTotals);
    setIsCreditTotalsDataLoading(false);
  };

  const handleGoToPrimaryAccount = (accountId: number) => {
    navigate(`/accounts/${accountId}/transactions`);
  };

  const handleGoToCreditAccounts = (showColumn: string) => {
    navigate(`/accounts/credit/${showColumn}`);
  };

  useEffect(() => {
    getPrimaryAccountData();
    getCreditTotalsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {(isPrimaryAccountDataLoading || isCreditTotalsDataLoading) && (
        <div className='empty-list'>
          Getting your data...
          <br />
          <br />
          <CircularProgress color='success' />
        </div>
      )}
      {!isPrimaryAccountDataLoading &&
        !isCreditTotalsDataLoading &&
        primaryAccountBalance.accountId === null && (
          <div className='dashboard-empty-list'>
            <h4>No primary account found</h4>
            <Button
              className='mui-button'
              variant='outlined'
              size='medium'
              onClick={() => navigate("/accounts/primary")}
            >
              Create
            </Button>
            <h5>
              Start using Ledgers by ceating a primary spending account to track
              income and spending.
            </h5>
            <h5>
              Your primary account can be a checking, savings, credit card or
              even a cash account.
            </h5>
          </div>
        )}
      {!isPrimaryAccountDataLoading &&
        !isCreditTotalsDataLoading &&
        primaryAccountBalance.accountId !== null && (
          <React.Fragment>
            <div className='overview-title'>
              <h4>
                <strong>Overview</strong>
              </h4>
            </div>
            <div className='overview-widgets-container'>
              <Desktop
                primaryAccountBalance={primaryAccountBalance}
                creditTotals={creditTotals}
                handleGoToPrimaryAccount={handleGoToPrimaryAccount}
                handleGoToCreditAccounts={handleGoToCreditAccounts}
              />
              <Mobile
                primaryAccountBalance={primaryAccountBalance}
                creditTotals={creditTotals}
                handleGoToPrimaryAccount={handleGoToPrimaryAccount}
                handleGoToCreditAccounts={handleGoToCreditAccounts}
              />
            </div>
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

export default Overview;
