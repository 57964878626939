import React, { useState, useEffect } from 'react';
import { MdTimeline, MdEdit, MdClear } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import '../../../assets/css/list.css';
import { getMerchants, deleteMerchant } from '../services/requests';
import { MerchantModel } from '../models/MerchantModel';
import ConfirmDeleteDialog from '../../../shared/components/ConfirmDeleteDialog/ConfirmDeleteDialog';
import { UpdateResponseModel } from '../../../shared/models/UpdateResponseModel';
import ActionAlert, { State } from '../../../shared/components/ActionAlert/ActionAlert';

export default function ListMerchants() {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [merchants, setMerchants] = useState<MerchantModel[]>([]);
  const [merchant, setMerchant] = useState<MerchantModel>(new MerchantModel());
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
  });
  const { open } = actionAlertState;

  const handleResponse = (data: any, callback: Function)=> {
    if(typeof data === 'string') {
      setActionAlertState({ 
        open: true, 
        messages: [ data ], 
        severity: 'error'
      });
    } else {
      callback(data);
    }
  }

  const getMerchantList = async () => {
    const data = await getMerchants();
    await handleResponse(data, setMerchants);
    setIsLoading(false);
  }

  const deleteByMerchantId = async () => {
    const deleteResult = (result: UpdateResponseModel) => {
      if(result.errors.length > 0) {
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
        getMerchantList();
      }
      setIsSubmitting(false);
    }
    await deleteMerchant(merchant, deleteResult);
  }

  useEffect(() => {
    async function hasMerchants() {
      await getMerchantList();
      return merchants && merchants.length > 0;
    }
    hasMerchants();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenDeleteDialog = (merchant: MerchantModel) => {
    if(merchant) {
      setMerchant(merchant);
      setOpenDeleteDialog(true);
    }
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = async () => {
    handleCancelDelete();
    if(merchant) {
      setIsSubmitting(true);
      await deleteByMerchantId();
    }
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
  };

  return (
    <React.Fragment>
      <h4>
        <strong>List</strong> Merchants
      </h4>
      <div className='list-header'>
        <Button
          className='mui-button'
          variant='outlined'
          color='success'
          size='medium'
          onClick={() => navigate("/")}
        >
          Dashboard
        </Button>
        <Button
          className='mui-button'
          variant='outlined'
          size='medium'
          onClick={() => navigate("/merchants/create")}
        >
          New
        </Button>
      </div>
      <div className='linear-progress-container'>
        {isSubmitting && <LinearProgress color='success' />}
      </div>
      {isLoading && (
        <div className='empty-list'>
          Getting merchants...
          <br />
          <br />
          <CircularProgress color='success' />
        </div>
      )}
      {!isLoading && merchants?.length === 0 && (
        <div className='empty-list'>
          <h4>No merchants found</h4>
        </div>
      )}
      {!isLoading && merchants?.length > 0 && (
        <table>
          <thead>
            <tr>
              <th className='list-item name'>Name</th>
              <th className='list-item hide'>Type</th>
              <th className='list-item-right actions'>Actions</th>
            </tr>
          </thead>
          <tbody className='scrollable-content'>
            {merchants.map((row) => (
              <tr key={row.merchantId}>
                <td className='list-item name'>{row.name}</td>
                <td className='list-item hide'>{row.merchantType}</td>
                <td className='list-item-right actions'>
                  <Button
                    variant='text'
                    onClick={() =>
                      navigate(`/merchants/${row.merchantId}/transactions`)
                    }
                    color='inherit'
                  >
                    <MdTimeline />
                  </Button>
                  <Button
                    variant='text'
                    onClick={() => navigate(`/merchants/${row.merchantId}`)}
                    color='info'
                  >
                    <MdEdit />
                  </Button>
                  <Button
                    variant='text'
                    onClick={() => handleOpenDeleteDialog(row)}
                    color='error'
                  >
                    <MdClear />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <ConfirmDeleteDialog
        title={"Confirm Delete Merchant"}
        text={"Deleting this merchant cannot be undone. Continue?"}
        openDialog={openDeleteDialog}
        handleCancel={handleCancelDelete}
        handleDelete={handleDelete}
      />
      <ActionAlert
        open={open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
      />
    </React.Fragment>
  );
}