import { memo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import './dialog.css';

const ConfirmActionDialog = ({ 
  title,
  text,
  openDialog,
  handleCancel,
  handleAction,
  actionName
}) => {

  return (
      <Dialog open={openDialog}>
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="dialog-action-container">
            <Button 
              className='mui-button'
              variant='outlined' 
              color='error' 
              size='medium'
              onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className='mui-button'
              variant='outlined' 
              color='success' 
              size='medium'
              onClick={handleAction}>
              {actionName}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
  );
};

export default memo(ConfirmActionDialog);