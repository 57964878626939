import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import NumberFormat from 'react-number-format';
import CircularProgress from '@mui/material/CircularProgress';

import '../../../../assets/css/form.css';
import { getAccountSelectList } from '../../services/requests';
import { recordInterest } from '../services/requests';
import { SelectListItemModel } from '../../../../shared/models/SelectListItemModel';
import { PayFeeModel } from '../models/PayFeeModel';
import { CreateResponseModel } from '../../../../shared/models/CreateResponseModel';
import ActionAlert, { State } from '../../../../shared/components/ActionAlert/ActionAlert';

export default function RecordInterest() {

  const { accountId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accounts, setAccounts] = useState<SelectListItemModel[]>([]);
  const [payment, setPayment] = useState<PayFeeModel>(new PayFeeModel());
  const [hasError, setHasError] = useState<boolean>(false);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
  });

  const handleResponse = (data: any, callback: Function)=> {
    if(typeof data === 'string') {
      setActionAlertState({ 
        open: true, 
        messages: [ data ], 
        severity: 'error'
      });
    } else {
      callback(data);
    }
  }

  const getAccounts = async () => {
    const data = await getAccountSelectList();
    await handleResponse(data, setAccounts);
  }

  const pay = async () => {
    const createResult = (result: CreateResponseModel) => {
      if(result.errors.length > 0) {
        setHasError(true);
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setHasError(false);
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
      }
      setIsSubmitting(false);
    }
    await recordInterest(payment, createResult);
  }

  const handleUpdateValue = (name, value) => {
    if(name === 'amount') {
      value = value.replace(',', '');
    }
    setPayment(x => ({...x, [name]: value}));
  }

  const handleInputChange = (e) => {
    handleUpdateValue(e.target.name, e.target.value);
  }

  useEffect(() => {
    handleUpdateValue('accountId', accountId);
    getAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await pay();
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
    if(!hasError) {
      navigate(`/accounts/${accountId}/transactions`);
    }
  };

  return (
    <React.Fragment>
      <h4><strong>Record</strong> Account Interest</h4>
      <div className='form-container'>
        <form onSubmit={handleSubmit}>
        <div className='form-input-container'>
          <select 
            id='sourceAccountId' 
            name='sourceAccountId' 
            value={payment.accountId} 
            onChange={handleInputChange}
            required
            >
            <option key='0' value={''} disabled={true}>Select Account...</option>
            {accounts.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
            </select>
          </div>
          <div className='form-input-container'>
            <NumberFormat
              key='amount'
              name='amount'
              value={payment.amount}
              className='value'
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'input'}
              onChange={handleInputChange} 
              required={true}
              min={0}
            />
          </div>
          <div className='form-input-container'>
            <input 
              key='date'
              name='date' 
              type='date' 
              placeholder='Date' 
              autoComplete='off' 
              onChange={handleInputChange} 
            />
          </div>
          <div className='form-input-container'>
            <textarea 
              key='notes' 
              name='notes' 
              placeholder='Notes' 
              autoComplete='off' 
              onChange={handleInputChange} 
              maxLength={1000} 
            />
          </div>
          <div className='form-buttons-container'>
            <Button 
              className='mui-button' 
              variant='outlined' 
              color='error' 
              size='medium' 
              onClick={() => navigate(`/accounts/${accountId}/transactions`)}>
              Cancel
            </Button>
            <Button 
              className='mui-button' 
              variant='outlined' 
              color='success' 
              size='medium' 
              type='submit'>
              {!isSubmitting && (
                <span>Submit</span>
              )}
              {isSubmitting && (
                <CircularProgress 
                  color='success' 
                  size={24}
                />
              )}
            </Button>
          </div>
        </form>
      </div>
      <ActionAlert 
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
      />
    </React.Fragment>
  );
}