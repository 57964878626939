import React, { useState, useEffect } from 'react';
import { MdOutlineAccountCircle, MdOutlineNoAccounts } from 'react-icons/md';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import '../../../assets/css/list.css';
import { getUsers, disableUser, enableUser } from '../services/requests';
import { getUser } from '../../../shared/services/localStorageService';
import { UserModel } from '../models/UserModel';
import { CurrentUserModel } from '../../../shared/models/CurrentUserModel';
import { UserStatusTypeEnum } from '../models/UserStatusTypeEnum';
import { UserRoleTypeEnum } from '../models/UserRoleTypeEnum';
import { UpdateResponseModel } from '../../../shared/models/UpdateResponseModel';
import ConfirmActionDialog from '../../../shared/components/ConfirmActionDialog/ConfirmActionDialog';
import ActionAlert, { State } from '../../../shared/components/ActionAlert/ActionAlert';

export default function ManageUsers() {

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [user, setUser] = useState<UserModel>(new UserModel());
  const [currentUser, setCurrentUser] = useState<CurrentUserModel>(new CurrentUserModel());
  const [openDisableDialog, setOpenDisableDialog] = useState(false);
  const [openEnableDialog, setOpenEnableDialog] = useState(false);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
  });

  const handleResponse = (data: any, callback: Function)=> {
    if(typeof data === 'string') {
      setActionAlertState({ 
        open: true, 
        messages: [ data ], 
        severity: 'error'
      });
    } else {
      callback(data);
    }
  }

  const getUserList = async () => {
    const data = await getUsers();
    await handleResponse(data, setUsers);
    setIsLoading(false);
  }

  const disable = async () => {
    const updateResult = (result: UpdateResponseModel) => {
      if(result.errors.length > 0) {
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
        getUserList();
      }
      setIsSubmitting(false);
    }
    await disableUser(user, updateResult);
  }

  const enable = async () => {
    const updateResult = (result: UpdateResponseModel) => {
      if(result.errors.length > 0) {
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
        getUserList();
      }
      setIsSubmitting(false);
    }
    await enableUser(user, updateResult);
  }

  useEffect(() => {
    const current = getUser();
    setCurrentUser(current);
    async function hasUsers() {
      await getUserList();
      return users && users.length > 0;
    }
    hasUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenDisableDialog = (user: UserModel) => {
    if(user) {
      setUser(user);
      setOpenDisableDialog(true);
    }
  };

  const handleCancelDisable = () => {
    setOpenDisableDialog(false);
  };

  const handleDisable = async () => {
    handleCancelDisable();
    if(user) {
      setIsSubmitting(true);
      await disable();
    }
  };

  const handleOpenEnableDialog = (user: UserModel) => {
    if(user) {
      setUser(user);
      setOpenEnableDialog(true);
    }
  };

  const handleCancelEnable = () => {
    setOpenEnableDialog(false);
  };

  const handleEnable = async () => {
    handleCancelEnable();
    if(user) {
      setIsSubmitting(true);
      await enable();
    }
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
  };

  return (
    <React.Fragment>
      <h4>
        <strong>Manage</strong> Users
      </h4>
      <div className='linear-progress-container'>
        {isSubmitting && <LinearProgress color='success' />}
      </div>
      {isLoading && (
        <div className='empty-list'>
          Getting users...
          <br />
          <br />
          <CircularProgress color='success' />
        </div>
      )}
      {!isLoading && users?.length === 0 && (
        <div className='empty-list'>
          <h4>No users found</h4>
        </div>
      )}
      {users?.length > 0 && (
        <table>
          <thead>
            <tr>
              <th className='list-item'>Type</th>
              <th className='list-item name'>Name</th>
              <th className='list-item hide'>Username</th>
              <th className='list-item hide'>Email</th>
              <th className='list-item hide'>Failed Logins</th>
              <th className='list-item-right actions'>Actions</th>
            </tr>
          </thead>
          <tbody className='scrollable-content'>
            {users.map((row) => (
              <tr key={row.userId}>
                <td className='list-item'>
                  {row.userRoleType === UserRoleTypeEnum.Admin
                    ? "Admin"
                    : "Basic"}
                </td>
                <td className='list-item name'>
                  {row.firstName + " " + row.lastName}
                </td>
                <td className='list-item hide'>{row.username}</td>
                <td className='list-item hide'>{row.email}</td>
                <td className='list-item hide'>{row.failedLoginCount}</td>
                <td className='list-item-right actions'>
                  {row.userStatusType === UserStatusTypeEnum.Enabled && (
                    <Button
                      variant='text'
                      onClick={() => handleOpenDisableDialog(row)}
                      color='success'
                      disabled={row.username === currentUser.username}
                    >
                      <MdOutlineAccountCircle />
                    </Button>
                  )}
                  {row.userStatusType === UserStatusTypeEnum.Disabled && (
                    <Button
                      variant='text'
                      onClick={() => handleOpenEnableDialog(row)}
                      color='error'
                      disabled={row.username === currentUser.username}
                    >
                      <MdOutlineNoAccounts />
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <ConfirmActionDialog
        title={"Confirm Disable User"}
        text={"Are you sure you want to disable this user?"}
        openDialog={openDisableDialog}
        handleCancel={handleCancelDisable}
        handleAction={handleDisable}
        actionName={"Disable"}
      />
      <ConfirmActionDialog
        title={"Confirm Enable User"}
        text={"Are you sure you want to enable this user?"}
        openDialog={openEnableDialog}
        handleCancel={handleCancelEnable}
        handleAction={handleEnable}
        actionName={"Enable"}
      />
      <ActionAlert
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
      />
    </React.Fragment>
  );
}