import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MdOutlineAdd } from 'react-icons/md';
import Button from '@mui/material/Button';
import NumberFormat from 'react-number-format';
import CircularProgress from '@mui/material/CircularProgress';

import '../../../../assets/css/form.css';
import { getBudgetItem } from '../../../Budgets/services/requests';
import { getAccountSelectList } from '../../../Accounts/services/requests';
import { createMerchant, getMerchantSelectList } from '../../services/requests';
import { payMerchant } from '../services/requests'
import { ManageBudgetItemModel } from '../../../Budgets/models/ManageBudgetItemModel';
import { SelectListItemModel } from '../../../../shared/models/SelectListItemModel';
import { CreateMerchantModel } from '../../models/CreateMerchantModel';
import { PayMerchantModel } from '../models/PayMerchantModel';
import CreateMerchantDialog from './CreateMerchantDialog';
import { CreateResponseModel } from '../../../../shared/models/CreateResponseModel';
import { PrimaryAccountBalanceModel } from '../../../Dashboard/models/PrimaryAccountBalanceModel';
import ActionAlert, { State } from '../../../../shared/components/ActionAlert/ActionAlert';

export default function PayMerchant() {

  const { budgetId } = useParams();
  const { budgetItemId } = useParams();
  const { merchantId } = useParams();
  const { accountId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accounts, setAccounts] = useState<SelectListItemModel[]>([]);
  const [merchants, setMerchants] = useState<SelectListItemModel[]>([]);
  const [payment, setPayment] = useState<PayMerchantModel>(new PayMerchantModel());
  const [hasError, setHasError] = useState<boolean>(false);
  const [shouldNavigate, setShouldNavigate] = useState<boolean>(true);
  const [openCreateMerchantDialog, setOpenCreateMerchantDialog] = useState(false);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
  });

  const handleResponse = (data: any, callback: Function)=> {
    if(typeof data === 'string') {
      setActionAlertState({ 
        open: true, 
        messages: [ data ], 
        severity: 'error'
      });
    } else {
      callback(data);
    }
  }

  const setBudgetItemAsPayment = (data: ManageBudgetItemModel) => {
    let payMerchant = new PayMerchantModel();
    payMerchant.accountId = data.accountId;
    payMerchant.merchantId = data.institutionId;
    payMerchant.amount = data.amount;
    if(data.dueDate !== null) {
      payMerchant.date = data.dueDateReverseFormat as string;
    }
    setPayment(payMerchant);
  }

  const setNewSelectedMerchant = async (data: any) => {
    setMerchants(data);
    const newMerchantId = data.reduce((maxId, m) => Math.max(maxId, m.id), 0);
    if(newMerchantId !== 0) {
      handleUpdateValue('merchantId', newMerchantId);
    }
  }

  const getAccounts = async () => {
    const data = await getAccountSelectList();
    await handleResponse(data, setAccounts);
  }

  const getMerchants = async (isCreating: boolean = false) => {
    const data = await getMerchantSelectList();
    if(isCreating) {
      await handleResponse(data, setNewSelectedMerchant);
    }
    else {
      await handleResponse(data, setMerchants);
    }
  }

  const getBudgetItemById = async () => {
    const data = await getBudgetItem(budgetId, budgetItemId);
    await handleResponse(data, setBudgetItemAsPayment);
  }

  const createNewMerchant = async (merchant: CreateMerchantModel) => {
    const createResult = (result: CreateResponseModel) => {
      if(result.errors.length > 0) {
        setShouldNavigate(false);
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setShouldNavigate(false);
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
        getMerchants(true);
      }
      setIsSubmitting(false);
    }
    await createMerchant(merchant, createResult);
  }

  const pay = async () => {
    const createResult = (result: CreateResponseModel) => {
      if(result.errors.length > 0) {
        setHasError(true);
        setShouldNavigate(true);
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setHasError(false);
        setShouldNavigate(true);
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
      }
      setIsSubmitting(false);
    }
    await payMerchant(payment, createResult);
  }

  const navigateBack = () => {
    if(budgetId) {
      navigate(`/budgets/${budgetId}/manage`);
    } else if (merchantId) {
      navigate(`/merchants/${merchantId}/transactions`);
    } else if(accountId) {
      navigate(`/accounts/${accountId}/transactions`);
    } else {
      navigate('/');
    }
  }

  const handleUpdateValue = (name, value) => {
    if(name === 'amount') {
      value = value.replace(',', '');
    }
    setPayment(x => ({...x, [name]: value}));
  }

  const handleInputChange = (e) => {
    handleUpdateValue(e.target.name, e.target.value);
  }

  useEffect(() => {
    getAccounts();
    getMerchants();
    if(budgetId !== undefined && budgetItemId !== undefined) {
      getBudgetItemById();
    } else {
      handleUpdateValue('merchantId', merchantId);
      handleUpdateValue('accountId', accountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenCreateMerchantDialog = () => {
    setOpenCreateMerchantDialog(true);
  };

  const handleCancelCreateMerchant = () => {
    setOpenCreateMerchantDialog(false);
  };
 
  const handleCreateMerchant = async (merchant: CreateMerchantModel) => {
    handleCancelCreateMerchant();
    setIsSubmitting(true);
    await createNewMerchant(merchant);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await pay();
  };

  const handleCancel = () => {
    navigateBack();
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
    if(!hasError && shouldNavigate) {
      navigateBack();
    }
  };

  return (
    <React.Fragment>
      <h4><strong>Pay</strong> Merchant</h4>
      <div className='form-container'>
        <form onSubmit={handleSubmit}>
          <div className='form-input-container'>
            <select 
              id='accountId' 
              name='accountId' 
              value={payment.accountId} 
              onChange={handleInputChange}
              required
            >
              <option key='0' value={''} disabled={true}>Select Account...</option>
              {accounts.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
            </select>
          </div>
          <div className='form-input-container'>
            <select 
              id='merchantId' 
              name='merchantId' 
              value={payment.merchantId} 
              className='editable-select-list'
              onChange={handleInputChange}
              required
            >
              <option key='0' value={''} disabled={true}>Select Merchant...</option>
              {merchants.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
            </select>
            <span className='add-entity-container'>
              <Button 
                variant='text' 
                onClick={() => handleOpenCreateMerchantDialog()} 
                color='success'
                size='small'
              >
                <MdOutlineAdd />
              </Button>
            </span>
          </div>
          <div className='form-input-container'>
            <NumberFormat
              key='amount'
              name='amount'
              value={payment.amount}
              className='value'
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'input'}
              onChange={handleInputChange} 
              required={true}
              min={0}
            />
          </div>
          <div className='form-input-container'>
            <input 
              key='date'
              name='date' 
              type='date' 
              placeholder='Date' 
              autoComplete='off' 
              defaultValue={payment.date as string | undefined}
              onChange={handleInputChange} 
            />
          </div>
          <div className='form-input-container'>
            <textarea 
              key='notes' 
              name='notes' 
              placeholder='Notes' 
              autoComplete='off' 
              onChange={handleInputChange} 
              maxLength={1000} 
            />
          </div>
          <div className='form-buttons-container'>
            <Button 
              className='mui-button' 
              variant='outlined' 
              color='error' 
              size='medium' 
              onClick={handleCancel}>
              Cancel
            </Button>
            <Button 
              className='mui-button' 
              variant='outlined' 
              color='success' 
              size='medium' 
              type='submit'>
              {!isSubmitting && (
                <span>Submit</span>
              )}
              {isSubmitting && (
                <CircularProgress 
                  color='success' 
                  size={24}
                />
              )}
            </Button>
          </div>
        </form>
      </div>
      <CreateMerchantDialog 
        openDialog={openCreateMerchantDialog} 
        handleResponse={handleResponse}
        handleCancel={handleCancelCreateMerchant} 
        handleCreateMerchant={handleCreateMerchant} 
      />
      <ActionAlert 
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
      />
    </React.Fragment>
  );
}