import configuration from '../../../api-config.json';
import { GETLIST, PUT } from '../../../shared/services/apiRequests';
import { UserModel } from '../models/UserModel';
import { UpdateUserModel } from '../models/UpdateUserModel';
import { UpdateResponseModel } from '../../../shared/models/UpdateResponseModel';

const entity = 'users';
const baseUrl = `${configuration.API_URL}/${entity}`;
const routeVersion1 = '/v1';

export const getUser = async (): Promise<Array<UpdateUserModel> | string> => {
  const url = baseUrl + routeVersion1 ;
  return await GETLIST<Array<UpdateUserModel>>(url, true);
}

export const getUsers = async (): Promise<Array<UserModel> | string> => {
  const url = baseUrl + routeVersion1 + '/all' ;
  return await GETLIST<Array<UserModel>>(url, true);
}

export const updateUser = async (data: UpdateUserModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1;
  const successMessage = 'User information updated successfully!';
  await PUT<UpdateUserModel>(data, url, successMessage, true, handleResult);
}

export const disableUser = async (data: UserModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/' + data.userId + '/disable';
  const successMessage = `${data?.username} was disabled successfully!`;
  await PUT<UserModel>(data, url, successMessage, true, handleResult);
}

export const enableUser = async (data: UserModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/' + data.userId + '/enable';
  const successMessage = `${data?.username} was enabled successfully!`;
  await PUT<UserModel>(data, url, successMessage, true, handleResult);
}
