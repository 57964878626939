export class UserModel {
  userId: number = 0;
  userRoleType: number = 0;
  userStatusType: number = 0;
	username: string = '';
	email: string = '';
	firstName: string = '';
	lastName: string = '';
  failedLoginCount: number = 0;
}
