import { getToken } from './localStorageService';
import { CreateResponseModel } from '../../shared/models/CreateResponseModel';
import { UpdateResponseModel } from '../models/UpdateResponseModel';
import { handleError } from '../../shared/services/apiErrorHandlingService';

function getHeaders(isAuthenticated: boolean) {
  const headers = new Headers({ 
    'Content-Type': 'application/json'
  });

  if(isAuthenticated) {
    const token = getToken();
    headers.append('Authorization', `Bearer ${token}`)
  }
  return headers;
}

export async function POST<T> (
  data: T, 
  url: string, 
  successMessage: string,
  isAuthenticated: boolean,
  handleResult: Function): Promise<CreateResponseModel  | any> {
  const headers = getHeaders(isAuthenticated);
  const result = new CreateResponseModel();
  await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers 
  })
  .then(response => {
    const error = handleError(response);
    if(error.length > 0) {
      result.errors.push(error);
    }
    if(response.status === 400) {
      response.json().then(errors => {
        result.errors.push(...errors);
        handleResult(result);
      });
    }
    if(response.status === 200) {
      result.success = successMessage;
    }
    if(response.status === 201) {
      result.success = successMessage;
    }
    handleResult(result);
  })
  .catch(error => {
    if (typeof error.json === 'function') {
      error.json().then(jsonError => {
        result.errors.push(...jsonError);
        handleResult(result);
      }).catch(genericError => {
        result.errors.push(genericError);
        handleResult(result);
      });
    }
  });
}

export async function GET<T> (
  url: string, 
  isAuthenticated: boolean
): Promise<T | string> {
  const headers = getHeaders(isAuthenticated);
  const response = await fetch(url, {
    method: 'GET',
    headers 
  });
  const error = handleError(response);
  if(error.length > 0) {
    return error;
  }
  return response.json();
}

export async function GETLIST<T> (
  url: string, 
  isAuthenticated: boolean
): Promise<T | string> {
  const headers = getHeaders(isAuthenticated);
  const response = await fetch(url, {
    method: 'GET',
    headers 
  });
  const error = handleError(response);
  if(error.length > 0) {
    return error;
  }
  return response.json();
}

export async function PUT<T>(
  data: T, 
  url: string, 
  successMessage: string,
  isAuthenticated: boolean,
  handleResult: Function): Promise<UpdateResponseModel | any> {
  const headers = getHeaders(isAuthenticated);
  const result = new UpdateResponseModel();
  await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers 
  })
  .then(response => {
    const error = handleError(response);
    if(error.length > 0) {
      result.errors.push(error);
    }
    if(response.status === 400) {
      response.json().then(errors => {
        result.errors.push(...errors);
        handleResult(result);
      });
    }
    if(response.status === 200) {
      result.success = successMessage;
    }
    if(response.status === 201) {
      result.success = successMessage;
    }
    if(response.status === 204) {
      result.success = successMessage;
    }
    handleResult(result);
  })
  .catch(error => {
    if (typeof error.json === 'function') {
      error.json().then(jsonError => {
        result.errors.push(...jsonError);
        handleResult(result);
      }).catch(genericError => {
        result.errors.push(genericError);
        handleResult(result);
      });
    }
  });
}

export async function DELETE(
  url: string, 
  successMessage: string,
  isAuthenticated: boolean,
  handleResult: Function): Promise<UpdateResponseModel | any> {
  const headers = getHeaders(isAuthenticated)
  const result = new UpdateResponseModel();
  await fetch(url, {
    method: 'DELETE',
    headers 
  })
  .then(response => {
    const error = handleError(response);
    if(error.length > 0) {
      result.errors.push(error);
    }
    if(response.status === 400) {
      response.json().then(errors => {
        result.errors.push(...errors);
        handleResult(result);
      });
    }
    if(response.status === 200) {
      result.success = successMessage;
    }
    if(response.status === 204) {
      result.success = successMessage;
    }
    handleResult(result);
  })
  .catch(error => {
    if (typeof error.json === 'function') {
      error.json().then(jsonError => {
        result.errors.push(...jsonError);
        handleResult(result);
      }).catch(genericError => {
        result.errors.push(genericError);
        handleResult(result);
      });
    }
  });
}
