import { useEffect, useState } from 'react';

import './user.css';
import userAdmin from '../../../assets/images/userAdmin.png';
import userBasic from '../../../assets/images/userBasic.png';
import { getUser } from '../../../shared/services/localStorageService';

const User = ({
  currentUser,
  setCurrentUser
}) => {
  
  useEffect(() => {
    if(!currentUser) {
      const user = getUser();
      setCurrentUser(user);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='sidebar-user-container'>
      <img src={currentUser?.isAdmin ? userAdmin : userBasic} alt=''/>
      <div id='sidebarUser' className='sidebar-user-info'>
        <div className='sidebar-username'>{currentUser?.firstName + ' ' + currentUser?.lastName}</div>
        <div className='sidebar-user-role'>{currentUser?.isAdmin ? 'Admin' : 'Basic'} User</div>
      </div>
    </div>
  );
}

export default User;