import { Navigate } from 'react-router-dom';

import { getToken } from '../../services/localStorageService';

const ProtectedRoute = ({
  children
}) => {

  const isAuthenticated = () => {
    const token = getToken();
    if(token) {
      const parsedToken = JSON.parse(window.atob(token.split(".")[1]));
      if(parsedToken.exp * 1000 > Date.now()) {
        return true;
      }
    }
    return false;
  }

  if(!isAuthenticated()) {
    return  (
      <Navigate to='/sign-in' replace/>
    );
  }
  return children;
}

export default ProtectedRoute;
