import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import '../../../assets/css/form.css';
import { getAccountTypes, getAccount, updateAccount } from '../services/requests';
import { SelectListItemModel } from '../../../shared/models/SelectListItemModel';
import { UpdateAccountModel } from '../models/UpdateAccountModel';
import { UpdateResponseModel } from '../../../shared/models/UpdateResponseModel';
import ActionAlert, { State } from '../../../shared/components/ActionAlert/ActionAlert';

export default function UpdateAccount() {

  const { accountId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accountTypes, setAccountTypes] = useState<SelectListItemModel[]>([]);
  const [account, setAccount] = useState<UpdateAccountModel>({});
  const [hasError, setHasError] = useState<boolean>(false);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
  });

  const handleResponse = (data: any, callback: Function)=> {
    if(typeof data === 'string') {
      setActionAlertState({ 
        open: true, 
        messages: [ data ], 
        severity: 'error'
      });
    } else {
      callback(data);
    }
  }

  const getPrimaryAccountTypeSelectList = () => {
    let types: Array<SelectListItemModel> =
      [ new SelectListItemModel(2, 'Checking'), 
        new SelectListItemModel(3, 'Credit Card'), 
        new SelectListItemModel(6, 'Savings'), 
        new SelectListItemModel(7, 'Cash'), 
    ];
    return types;
  }

  const getAccountTypeSelectList = async (primary) => {
    let data;
    if(primary) {
      data = getPrimaryAccountTypeSelectList();
    } else {
      data = await getAccountTypes();
    }
    await handleResponse(data, setAccountTypes);
  }

  const getAccountById = async () => {
    const data = await getAccount(accountId);
    await handleResponse(data, setAccount);
  }

  const update = async () => {
    const updateResult = (result: UpdateResponseModel) => {
      if(result.errors.length > 0) {
        setHasError(true);
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setHasError(false);
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
      }
      setIsSubmitting(false);
    }
    await updateAccount(account, updateResult);
  }

  const handleUpdateValue = (name, value) => {
    if(name === 'creditLimit' && value === '') {
      value = null;
    } 
    if(name === 'isPrimary') {
      value = !account.isPrimary;
      getAccountTypeSelectList(value);
    }
    setAccount(x => ({...x, [name]: value}));
  }

  const handleInputChange = (e) => {
    handleUpdateValue(e.target.name, e.target.value);
  }

  useEffect(() => {
    getAccountTypeSelectList(false);
    getAccountById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await update();
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
    if(!hasError) {
      navigate('/accounts');
    }
  };

  return (
    <React.Fragment>
      <h4><strong>Update</strong> Account</h4>
      <div className='form-container'>
        <form onSubmit={handleSubmit}>
          <div className='form-input-container'>
            <select 
              id='accountTypeId' 
              name='accountTypeId' 
              value={account.accountTypeId} 
              onChange={handleInputChange}
              required
            >
              <option key='0' value={''} disabled={true}>Select Account Type...</option>
              {accountTypes.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
            </select>
          </div>
          <div className='form-input-container'>
            <input 
              key='name' 
              name='name' 
              type='text' 
              placeholder='Account Name' 
              autoComplete='off' 
              defaultValue={account.name} 
              onChange={handleInputChange} 
              required 
              minLength={3} 
              maxLength={50} 
            />
          </div>
          <div className='form-input-container'>
            <input 
              key='startingBalance' 
              name='startingBalance' 
              type='number' 
              step='0.01' 
              placeholder='Starting Balance' 
              autoComplete='off' 
              defaultValue={account.startingBalance} 
              onChange={handleInputChange} 
              required 
              min={0} 
            />
          </div>
          <div className='form-input-container'>
          <input 
              key='creditLimit' 
              name='creditLimit' 
              type='number' 
              step='0.01' 
              placeholder='Credit Limit' 
              autoComplete='off' 
              defaultValue={account.creditLimit} 
              onChange={handleInputChange} 
            />
          </div>
          <div className='form-input-container'>
            <input 
              key='openDate' 
              name='openDate' 
              type='date' 
              placeholder='Open Date' 
              autoComplete='off' 
              defaultValue={account.openDate} 
              onChange={handleInputChange} 
            />
          </div>
          <div className='form-input-container'>
            <textarea 
              key='url' 
              name='url' 
              placeholder='Url' 
              autoComplete='off' 
              defaultValue={account.url} 
              onChange={handleInputChange} 
              maxLength={500} 
            />
          </div>
          <div className='form-checkbox-container'>
            <input 
              key='isPrimary' 
              name='isPrimary' 
              type='checkbox' 
              checked={account.isPrimary || false} 
              onChange={handleInputChange} 
            />Is Primary Account
          </div>
          <div className='form-buttons-container'>
            <Button 
              className='mui-button' 
              variant='outlined' 
              color='error' 
              size='medium' 
              onClick={() => navigate('/accounts')}>
              Cancel
            </Button>
            <Button 
              className='mui-button' 
              variant='outlined' 
              color='success' 
              size='medium' 
              type='submit'>
              {!isSubmitting && (
                <span>Submit</span>
              )}
              {isSubmitting && (
                <CircularProgress 
                  color='success' 
                  size={24}
                />
              )}
            </Button>
          </div>
        </form>
      </div>
      <ActionAlert 
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
      />
    </React.Fragment>
  );
}
