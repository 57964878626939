import configuration from '../../../api-config.json';
import { POST, GET, GETLIST, PUT, DELETE } from '../../../shared/services/apiRequests';
import { IncomeModel } from '../models/IncomeModel';
import { CreateResponseModel } from '../../../shared/models/CreateResponseModel';
import { CreateIncomeModel } from '../models/CreateIncomeModel';
import { UpdateIncomeModel } from '../models/UpdateIncomeModel';
import { UpdateResponseModel } from '../../../shared/models/UpdateResponseModel';
import { SelectListItemModel } from '../../../shared/models/SelectListItemModel';

const entity = 'income';
const baseUrl = `${configuration.API_URL}/${entity}`;
const routeVersion1 = '/v1';

export const createIncome = async (data: CreateIncomeModel, handleResult: Function): Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1;
  const successMessage = `${data?.name} was created successfully!`;
  await POST<CreateIncomeModel>(data, url, successMessage, true, handleResult);
}

export const getIncome = async (id: string | undefined): Promise<UpdateIncomeModel | string> => {
  const url = baseUrl + routeVersion1+ '/' +  id;
  return await GET<UpdateIncomeModel>(url, true);
}

export const getIncomes = async (): Promise<Array<IncomeModel> | string> => {
  const url = baseUrl + routeVersion1 + '/list';
  return await GETLIST<Array<IncomeModel>>(url, true);
}

export const updateIncome = async (data: UpdateIncomeModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1;
  const successMessage = `${data?.name} was updated successfully!`;
  await PUT<UpdateIncomeModel>(data, url, successMessage, true, handleResult);
}

export const deleteIncome = async (data: IncomeModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/' +  data.incomeId;
  const successMessage = `${data.name} was deleted successfully!`;
  await DELETE(url, successMessage, true, handleResult);
}

export const getIncomeSelectList = async (): Promise<Array<SelectListItemModel> | string> => {
  const url = baseUrl + routeVersion1 + '/select-list' ;
  return await GETLIST<Array<SelectListItemModel>>(url, true);
}

export const getIncomeTypes = async (): Promise<Array<SelectListItemModel> | string> => {
  const url = baseUrl + routeVersion1 + '/types';
  return await GETLIST<Array<SelectListItemModel>>(url, true);
}
