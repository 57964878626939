export class LedgerTransactionModel {
  ledgerTransactionId: number = 0;
  ledgerEntryTypeId: number = 0;
  sourceInstitutionId: number = 0;
  sourceInstitutionName: string = '';
  destinationInstitutionId: number = 0;
  destinationInstitutionName: string = '';
  amount: number = 0;
  date: string = '';
  transactionDate: Date | null = null;
}
