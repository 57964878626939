import React from 'react';
import NumberFormat from 'react-number-format';

const FutureMonthForecast = ({
  currentMonth,
  nextMonth,
  balanceForward,
  forecast,
  handleGoToBudget
}) => {

  return (
    <React.Fragment>
      <div 
        className='month-totals-section'
        onClick={() => handleGoToBudget(forecast.budgetId)}
      >
        <h3><strong>{currentMonth}</strong></h3>
        <div className='month-totals-container'>
          <div className='month-totals-title'>
            Balance Forward
          </div>
          <div className='month-totals-amount'>
            <NumberFormat
              key='balanceForward'
              name='balanceForward'
              value={balanceForward}
              className={balanceForward < 0 ? 'negative-number' : 'positive-number'}
              prefix={'$'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'text'}
            />
          </div>
        </div>
        <div className='month-totals-container'>
          <div className='month-totals-title'>
            Budgeted Income
          </div>
          <div className='month-totals-amount'>
            <NumberFormat
              key='income'
              name='income'
              value={forecast.income}
              className={forecast.income < 0 ? 'negative-number' : 'inherit'}
              prefix={'$'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'text'}
            />
          </div>
        </div>
        <div className='month-totals-container'>
          <div className='month-totals-title'>
          Budgeted Expenses
          </div>
          <div className='month-totals-amount'>
            <NumberFormat
              key='expenses'
              name='expenses'
              value={forecast.expenses}
              className='negative-number'
              prefix={'$'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'text'}
            />
          </div>
        </div>
        <hr />
        <div className='month-totals-container'>
          <div className='month-totals-title'>
            Going Into {nextMonth}
          </div>
          <div className='month-totals-amount'>
            <NumberFormat
              key='balance'
              name='balance'
              value={forecast.balance}
              className={forecast.balance < 0 ? 'negative-number' : 'positive-number'}
              prefix={'$'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'text'}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FutureMonthForecast;