import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './forecast.css';
import { getPrimaryAccountForecast } from '../services/requests';
import { PrimaryAccountForecastModel } from '../models/PrimaryAccountForecastModel';
import CurrentMonthForecast from './CurrentMonth';
import FutureMonthForecast from './FutureMonth';

const Forecast = ({
  handleResponse
}) => {

  const today = new Date();
  const currentMonth = today.toLocaleDateString('en-US', { month: 'long' });
  const currentMonthId = today.getMonth();
  const nextMonthId = today.getMonth() + 1;
  const thirdMonthId = nextMonthId + 1;
  const fourthMonthId = thirdMonthId + 1;
  const fifthMonthId = fourthMonthId + 1;

  const nextMonth = new Date(nextMonthId < currentMonthId ? today.getFullYear() + 1 : today.getFullYear(), nextMonthId, 1).toLocaleDateString('en-US', { month: 'long' });
  const thirdMonth = new Date(thirdMonthId < nextMonthId ? today.getFullYear() + 1 : today.getFullYear(), thirdMonthId, 1).toLocaleDateString('en-US', { month: 'long' });
  const fourthMonth = new Date(fourthMonthId < thirdMonthId ? today.getFullYear() + 1 : today.getFullYear(), fourthMonthId, 1).toLocaleDateString('en-US', { month: 'long' });
  const fifthMonth = new Date(fifthMonthId < fourthMonthId ? today.getFullYear() + 1 : today.getFullYear(), fifthMonthId, 1).toLocaleDateString('en-US', { month: 'long' });

  const navigate = useNavigate();
  const [primaryAccountForecast, setPrimaryAccountForecast] = useState<PrimaryAccountForecastModel>(new PrimaryAccountForecastModel());

  const getPrimaryAccountData = async (mobile: boolean) => {
    const paf = await getPrimaryAccountForecast(mobile);
    await handleResponse(paf, setPrimaryAccountForecast);
  }

  useEffect(() => {
    let isMobile: boolean = false;
    getPrimaryAccountData(isMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoToBudget = (budgetId: number) => {
    if(budgetId > 0) {
      navigate(`/budgets/${budgetId}/manage`)
    }
  };

  return (
    <React.Fragment>
    {primaryAccountForecast?.currentMonth?.budgetId > 0 && (
        <React.Fragment>
          <h4><strong>Budget</strong> Forecast for <strong>{primaryAccountForecast.accountName}</strong></h4>
          <div className='month-container'>
            <CurrentMonthForecast
              currentMonth={currentMonth}
              nextMonth={nextMonth}
              forecast={primaryAccountForecast.currentMonth}
              handleGoToBudget={handleGoToBudget}
            />
            {primaryAccountForecast?.futureMonths?.length > 0 && (
              <FutureMonthForecast
                currentMonth={nextMonth}
                nextMonth={thirdMonth}
                balanceForward={primaryAccountForecast.currentMonth.currentMonthBalance}
                forecast={primaryAccountForecast.futureMonths[0]}
                handleGoToBudget={handleGoToBudget}
              />
            )}
            {primaryAccountForecast?.futureMonths?.length > 1 && (
              <FutureMonthForecast
                currentMonth={thirdMonth}
                nextMonth={fourthMonth}
                balanceForward={primaryAccountForecast.futureMonths[0].balance}
                forecast={primaryAccountForecast.futureMonths[1]}
                handleGoToBudget={handleGoToBudget}
              />
            )}
            {primaryAccountForecast?.futureMonths?.length > 2 && (
              <FutureMonthForecast
                currentMonth={fourthMonth}
                nextMonth={fifthMonth}
                balanceForward={primaryAccountForecast.futureMonths[1].balance}
                forecast={primaryAccountForecast.futureMonths[2]}
                handleGoToBudget={handleGoToBudget}
              />
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Forecast;