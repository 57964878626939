import configuration from '../../../api-config.json';
import { POST, GET, GETLIST, PUT, DELETE } from '../../../shared/services/apiRequests';
import { CreateAccountModel } from '../models/CreateAccountModel';
import { CreateResponseModel } from '../../../shared/models/CreateResponseModel';
import { AccountModel } from '../models/AccountModel';
import { CreditAccountModel } from '../models/CreditAccountModel';
import { UpdateAccountModel } from '../models/UpdateAccountModel';
import { UpdateResponseModel } from '../../../shared/models/UpdateResponseModel';
import { SelectListItemModel } from '../../../shared/models/SelectListItemModel';

const entity = 'accounts';
const baseUrl = `${configuration.API_URL}/${entity}`;
const routeVersion1 = '/v1';

export const createAccount = async (data: CreateAccountModel, handleResult: Function): Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1;
  const successMessage = `${data?.name} was created successfully!`;
  await POST<CreateAccountModel>(data, url, successMessage, true, handleResult);
}

export const getAccount = async (id: string | undefined): Promise<UpdateAccountModel | string> => {
  const url = baseUrl + routeVersion1+ '/' +  id;
  return await GET<UpdateAccountModel>(url, true);
}

export const getAccounts = async (): Promise<Array<AccountModel> | string> => {
  const url = baseUrl + routeVersion1 + '/list';
  return await GETLIST<Array<AccountModel>>(url, true);
}

export const getCreditAccounts = async (): Promise<Array<CreditAccountModel> | string> => {
  const url = baseUrl + routeVersion1 + '/list/credit-accounts';
  return await GETLIST<Array<CreditAccountModel>>(url, true);
}

export const updateAccount = async (data: UpdateAccountModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1;
  const successMessage = `${data?.name} was updated successfully!`;
  await PUT<UpdateAccountModel>(data, url, successMessage, true, handleResult);
}

export const deleteAccount = async (data: AccountModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/' +  data.accountId;
  const successMessage = `${data.name} was deleted successfully!`;
  await DELETE(url, successMessage, true, handleResult);
}

export const getAccountSelectList = async (): Promise<Array<SelectListItemModel> | string> => {
  const url = baseUrl + routeVersion1 + '/select-list' ;
  return await GETLIST<Array<SelectListItemModel>>(url, true);
}

export const getAccountTypes = async (): Promise<Array<SelectListItemModel> | string> => {
  const url = baseUrl + routeVersion1 + '/types';
  return await GETLIST<Array<SelectListItemModel>>(url, true);
}
