import React, { useState, useEffect } from 'react';
import { MdEdit, MdClear } from 'react-icons/md';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import NumberFormat from 'react-number-format';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import '../../../../assets/css/list.css';
import './listAccountTransactions.css';
import { getTransactions, deleteTransaction } from '../services/requests';
import { AccountTransactionsModel } from '../models/AccountTransactionsModel';
import { LedgerTransactionModel } from '../../../../shared/models/LedgerTransactionModel';
import ConfirmDeleteDialog from '../../../../shared/components/ConfirmDeleteDialog/ConfirmDeleteDialog';
import { UpdateResponseModel } from '../../../../shared/models/UpdateResponseModel';
import ActionAlert, { State } from '../../../../shared/components/ActionAlert/ActionAlert';

export default function ListAccountTransactions() {

  const { accountId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transactions, setTransactions] = useState<AccountTransactionsModel>(new AccountTransactionsModel());
  const [transaction, setTransaction] = useState<LedgerTransactionModel>(new LedgerTransactionModel());
  const [creditUsed, setCreditUsed] = useState<number>(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
  });
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(menuAnchor);
  const payAccountPath = `/accounts/${accountId}/transactions/pay`
  const payMerchantPath = `/merchants/${accountId}/transactions/pay`
  const payIncomePath = `/income/${accountId}/transactions/receive`
  const payFeePath = `/accounts/${accountId}/transactions/fee`
  const recordCashBackPath = `/accounts/${accountId}/transactions/cash-back`
  const recordInterestPath = `/accounts/${accountId}/transactions/interest`
  const transferFundsPath = `/accounts/${accountId}/transactions/transfer`

  const getTransactionList = async () => {
    const data = await getTransactions(accountId);
    setTransactions(data);
    setIsLoading(false);
    if(data.creditLimit !== null && data.creditLimit !== undefined) {
      const cl: number = data.creditLimit;
      const cb: number = data.currentBalance;
      const used = (cb/cl) * 100;
      setCreditUsed(used);
    }
  }

  const deleteByTransactionId = async () => {
    const deleteResult = (result: UpdateResponseModel) => {
      if(result.errors.length > 0) {
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
        getTransactionList();
      }
      setIsSubmitting(false);
    }
    await deleteTransaction(accountId, transaction, deleteResult);
  }

  useEffect(() => {
    async function hasTransactions() {
      await getTransactionList();
      return transactions && transactions.transactions.length > 0;
    }
    hasTransactions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActionsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  
  const handleActionMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleOpenDeleteDialog = (transaction: LedgerTransactionModel) => {
    if(transaction) {
      setTransaction(transaction);
      setOpenDeleteDialog(true);
    }
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = async () => {
    handleCancelDelete();
    if(transaction) {
      setIsSubmitting(true);
      await deleteByTransactionId();
    }
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
  };

  return (
    <React.Fragment>
      <h4>
        <strong>List</strong> Transactions for{' '}
        <strong>{transactions.accountName}</strong>
      </h4>
      <div className='list-header'>
        <Button
          className='mui-button'
          variant='outlined'
          color='success'
          size='medium'
          onClick={() => navigate('/accounts')}
        >
          Accounts
        </Button>
        <Button
          id='actions-button'
          aria-controls={openMenu ? 'actions-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={openMenu ? 'true' : undefined}
          className='mui-button'
          variant='outlined'
          size='medium'
          onClick={handleActionsMenu}
        >
          Actions...
        </Button>
        <Menu
          id='actions-menu'
          anchorEl={menuAnchor}
          open={openMenu}
          onClose={handleActionMenuClose}
          MenuListProps={{
            'aria-labelledby': 'actions-button',
          }}
        >
          <div className='account-transactions-menu-container'>
            <MenuItem onClick={handleActionMenuClose}>
              <NavLink to={payAccountPath}>Pay Account</NavLink>
            </MenuItem>
            <MenuItem onClick={handleActionMenuClose}>
              <NavLink to={payMerchantPath}>Pay Merchant</NavLink>
            </MenuItem>
            <MenuItem onClick={handleActionMenuClose}>
              <NavLink to={payIncomePath}>Pay Income</NavLink>
            </MenuItem>
            <MenuItem onClick={handleActionMenuClose}>
              <NavLink to={payFeePath}>Pay Fee</NavLink>
            </MenuItem>
            <MenuItem onClick={handleActionMenuClose}>
              <NavLink to={recordCashBackPath}>Record Cash Back</NavLink>
            </MenuItem>
            <MenuItem onClick={handleActionMenuClose}>
              <NavLink to={recordInterestPath}>Record Interest</NavLink>
            </MenuItem>
            <MenuItem onClick={handleActionMenuClose}>
              <NavLink to={transferFundsPath}>Transfer Funds</NavLink>
            </MenuItem>
          </div>
        </Menu>
      </div>
      <div className='linear-progress-container'>
        {isSubmitting && <LinearProgress color='success' />}
      </div>
      <div className='totals-section'>
        {transactions.creditLimit !== null && (
          <React.Fragment>
            <div className='totals-container'>
              <div className='totals-title'>Credit Limit:</div>
              <div className='totals-amount'>
                <NumberFormat
                  key='creditLimit'
                  name='creditLimit'
                  value={transactions.creditLimit}
                  prefix={'$'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                />
              </div>
            </div>
            <div className='totals-container'>
              <div className='totals-title'>Current Balance:</div>
              <div className='totals-amount'>
                <NumberFormat
                  key='currentBalance'
                  name='currentBalance'
                  value={transactions.currentBalance}
                  prefix={'$'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                />
              </div>
            </div>
            <div className='totals-container'>
              <div className='totals-title'>% Used:</div>
              <div className='totals-amount'>
                <NumberFormat
                  key='creditUsed'
                  name='creditUsed'
                  value={creditUsed}
                  suffix={'%'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={'text'}
                />
              </div>
            </div>
          </React.Fragment>
        )}
        {transactions.creditLimit === null && (
          <div className='totals-container'>
            <div className='totals-title'>Current Balance:</div>
            <div className='totals-amount'>
              <NumberFormat
                key='currentBalance'
                name='currentBalance'
                value={transactions.currentBalance}
                prefix={'$'}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={'text'}
              />
            </div>
          </div>
        )}
      </div>
      {isLoading && (
        <div className='empty-list'>
          Getting transactions...
          <br />
          <br />
          <CircularProgress color='success' />
        </div>
      )}
      {!isLoading && transactions?.transactions?.length === 0 && (
        <div className='empty-list'>
          <h4>No transactions found</h4>
        </div>
      )}
      {!isLoading && transactions?.transactions?.length > 0 && (
        <table>
          <thead>
            <tr>
              <th className='list-item hide'>From</th>
              <th className='list-item name'>To</th>
              <th className='list-item-right'>Amount</th>
              <th className='list-item-center hide'>Date</th>
              <th className='list-item-right actions'>Actions</th>
            </tr>
          </thead>
          <tbody className={
            transactions.creditLimit === null
              ? 'scrollable-content-transactions'
              : 'scrollable-content-credit-transactions'
          }>
            {transactions.transactions.map((row) => (
              <tr key={row.ledgerTransactionId}>
                <td className='list-item hide'>{row.sourceInstitutionName}</td>
                <td className='list-item name'>
                  {row.destinationInstitutionName}
                </td>
                <td className='list-item-right'>
                  <NumberFormat
                    value={row.amount}
                    className={
                      row.ledgerEntryTypeId !== 1
                        ? 'negative-number'
                        : 'positive-number'
                    }
                    prefix={'$'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={'text'}
                  />
                </td>
                <td className='list-item-center hide'>{row.date}</td>
                <td className='list-item actions'>
                  <Button
                    variant='text'
                    onClick={() =>
                      navigate(
                        `/accounts/${accountId}/transactions/${row.ledgerTransactionId}`
                      )
                    }
                    color='info'
                  >
                    <MdEdit />
                  </Button>
                  <Button
                    variant='text'
                    onClick={() => handleOpenDeleteDialog(row)}
                    color='error'
                  >
                    <MdClear />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <ConfirmDeleteDialog
        title={'Confirm Delete Transaction'}
        text={'Deleting this transaction cannot be undone. Continue?'}
        openDialog={openDeleteDialog}
        handleCancel={handleCancelDelete}
        handleDelete={handleDelete}
      />
      <ActionAlert
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
      />
    </React.Fragment>
  );
}
