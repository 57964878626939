import { useState } from 'react';

import './layout.scss';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Main from '../Main/Main';

const Layout = ({
  currentUser,
  setCurrentUser
}) => {

  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = (value: boolean) => {

    const sidebarUser = document.getElementById('sidebarUser');
    const toggleSidebar = document.getElementById('toggleSidebar');
    const outlet = document.getElementById('outlet');

    if(value) {
      sidebarUser?.classList.replace('sidebar-user-info', 'sidebar-user-info-hidden');
      toggleSidebar?.classList.replace('desktop-header-buttons', 'desktop-header-buttons-move-left');
      outlet?.classList.replace('outlet-container', 'outlet-container-move-left');
    } else {
      sidebarUser?.classList.replace('sidebar-user-info-hidden', 'sidebar-user-info');
      toggleSidebar?.classList.replace('desktop-header-buttons-move-left', 'desktop-header-buttons');
      outlet?.classList.replace('outlet-container-move-left', 'outlet-container');
    }

    setCollapsed(value);
  };

  const handleToggleSidebar = (value: boolean) => {
    setToggled(value);
  };

  return (
    <div className={`layout-container`}>
       <Header 
        collapsed={collapsed}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      />
      <Sidebar
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
      />
      <Main />
    </div>
  );
}

export default Layout;
