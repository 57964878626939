import configuration from '../../../api-config.json';
import { POST, GETLIST, PUT } from '../../../shared/services/apiRequests';
import { CreateInviteRequestModel } from '../models/CreateInviteRequestModel';
import { InviteRequestModel } from '../models/InviteRequestModel';
import { ValidateTokenModel } from '../../../auth/models/ValidateTokenModel';
import { CreateResponseModel } from '../../../shared/models/CreateResponseModel';
import { UpdateResponseModel } from '../../../shared/models/UpdateResponseModel';

const entity = 'invite-requests';
const baseUrl = `${configuration.API_URL}/${entity}`;
const routeVersion1 = '/v1';

export const createInviteRequest = async (data: CreateInviteRequestModel, handleResult: Function) :
Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/request-invite';
  const successMessage = 'Your invite request was successfully submitted!';
  data.publicKey = configuration.PUBLIC_KEY;
  await POST<CreateInviteRequestModel>(data, url, successMessage, false, handleResult);
}

export const validateInviteRequestToken = async (data: ValidateTokenModel, handleResult: Function) :
Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/validate-invite-token';
  const successMessage = 'Invitation Successfully Validated!';
  data.publicKey = configuration.PUBLIC_KEY;
  await POST<ValidateTokenModel>(data, url, successMessage, false, handleResult);
}

export const getInviteRequests = async (): Promise<Array<InviteRequestModel> | string> => {
  const url = baseUrl + routeVersion1 + '/all' ;
  return await GETLIST<Array<InviteRequestModel>>(url, true);
}

export const approveInviteRequest = async (data: InviteRequestModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/' + data.inviteRequestId + '/approve';
  const successMessage = 'Invitation successfully sent!';
  await PUT<InviteRequestModel>(data, url, successMessage, true, handleResult);
}

export const denyInviteRequest = async (data: InviteRequestModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/' + data.inviteRequestId + '/deny';
  const successMessage = 'Invitation successfully denied!';
  await PUT<InviteRequestModel>(data, url, successMessage, true, handleResult);
}
