import configuration from '../../../../api-config.json';
import { POST, GET, PUT, DELETE } from '../../../../shared/services/apiRequests';
import { PayAccountModel } from '../models/PayAccountModel';
import { PayFeeModel } from '../models/PayFeeModel';
import { RecordCashBackModel } from '../models/RecordCashBackModel';
import { RecordInterestModel } from '../models/RecordInterestModel';
import { TransferFundsModel } from '../models/TransferFundsModel';
import { CreateResponseModel } from '../../../../shared/models/CreateResponseModel';
import { UpdateAccountPaymentModel } from '../models/UpdateAccountPaymentModel';
import { UpdateResponseModel } from '../../../../shared/models/UpdateResponseModel';
import { AccountTransactionsModel } from '../models/AccountTransactionsModel';
import { LedgerTransactionModel } from '../../../../shared/models/LedgerTransactionModel';

const baseUrl = `${configuration.API_URL}/accounts`;
const routeVersion1 = '/v1';

export const payAccount = async (data: PayAccountModel, handleResult: Function): Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/transactions/pay';
  const successMessage = 'Payment saved successfully!';
  await POST<PayAccountModel>(data, url, successMessage, true, handleResult);
}

export const payFee = async (data: PayFeeModel, handleResult: Function): Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/transactions/fee';
  const successMessage = 'Payment saved successfully!';
  await POST<PayFeeModel>(data, url, successMessage, true, handleResult);
}

export const recordCashBack = async (data: RecordCashBackModel, handleResult: Function): Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/transactions/cash-back';
  const successMessage = 'Payment saved successfully!';
  await POST<RecordCashBackModel>(data, url, successMessage, true, handleResult);
}

export const recordInterest = async (data: RecordInterestModel, handleResult: Function): Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/transactions/interest';
  const successMessage = 'Payment saved successfully!';
  await POST<RecordInterestModel>(data, url, successMessage, true, handleResult);
}

export const transferFunds = async (data: TransferFundsModel, handleResult: Function): Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/transactions/transfer';
  const successMessage = 'Payment saved successfully!';
  await POST<TransferFundsModel>(data, url, successMessage, true, handleResult);
}

export const getTransaction = async (id: string | undefined, transactionId: string | undefined): Promise<UpdateAccountPaymentModel | any> =>  {
  const url = baseUrl + routeVersion1 + '/' + id + '/transactions/' + transactionId;
  return await GET<UpdateAccountPaymentModel>(url, true);
}

export const getTransactions = async (id: string | undefined): Promise<AccountTransactionsModel | any> =>  {
  const url = baseUrl + routeVersion1 + '/' + id + '/transactions';
  return await GET<AccountTransactionsModel>(url, true);
}

export const updatePayment = async (data: UpdateAccountPaymentModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/' +  data?.sourceInstitutionId + '/transactions/' + data?.ledgerTransactionId;
  const successMessage = 'Payment updated successfully!';
  await PUT<UpdateAccountPaymentModel>(data, url, successMessage, true, handleResult);
}

export const deleteTransaction = async (id: string | undefined, data: LedgerTransactionModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/' + id + '/transactions/' + data.ledgerTransactionId;
  const successMessage = 'Transaction was deleted successfully!';
  await DELETE(url, successMessage, true, handleResult);
}
