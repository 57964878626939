import React from 'react';
import NumberFormat from 'react-number-format';

import './widget.css';

const ClickableWidget = ({
  title='Loading...',
  amount='0',
  prefix='$',
  suffix='',
  borderColor,
  backgroundColor,
  handleOnClick,
  showAsNegative=false
}) => {

  return (
    <React.Fragment>
      <div className='clickable-desktop-widget'
        onClick={handleOnClick}
        style={{borderColor:borderColor, backgroundColor:backgroundColor}}>
        <div className='title'>{title}</div>
        <NumberFormat
          value={amount}
          className={showAsNegative ? 'desktop-negative-number' : 'desktop-positive-number'}
          prefix={prefix}
          thousandSeparator={true}
          decimalScale={2}
          fixedDecimalScale={true}
          displayType={'text'}
          suffix={suffix}
        />
      </div>
      <div className='clickable-mobile-widget'
        onClick={handleOnClick}
        style={{borderColor:borderColor, backgroundColor:backgroundColor}}>
        <div className='title'>{title}</div>
        <NumberFormat
          value={amount}
          className={showAsNegative ? 'negative-number' : 'positive-number'}
          prefix={prefix}
          thousandSeparator={true}
          decimalScale={2}
          fixedDecimalScale={true}
          displayType={'text'}
          suffix={suffix}
        />
      </div>
    </React.Fragment>
  );
}

export default ClickableWidget;
