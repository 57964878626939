import { clearSession } from './localStorageService';

export const handleError = (response: Response): string => {
  let errorMessage = '';
  switch(response.status) {
    case 401:
      clearSession();
      window.location.replace('/sign-in?expired=true');
      break;
    case 403:
      errorMessage = 'The resource is not authorized';
      break;
    case 404:
      errorMessage = 'The resource was not found';
      break;
    case 405:
      errorMessage = 'The API is misconfigured for the request';
      break;
    case 500:
      errorMessage = 'There was an unexpected server error';
      break;
    }
  return errorMessage;
}
