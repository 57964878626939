import React, { useState, useEffect } from "react";
import { MdClear, MdLink, MdOutlineAdd } from "react-icons/md";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import NumberFormat from "react-number-format";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./manageBudget.css";
import "../../../assets/css/list.css";
import "../../../assets/css/form.css";
import { getAccountSelectList } from "../../Accounts/services/requests";
import { getIncomeSelectList } from "../../Income/services/requests";
import { getMerchantSelectList } from "../../Merchants/services/requests";
import {
  getManageBudget,
  createBudgetEntry,
  deleteBudgetEntry,
  getBudgetCategoryTypes,
} from "../services/requests";
import { SelectListItemModel } from "../../../shared/models/SelectListItemModel";
import { ManageBudgetModel } from "../models/ManageBudgetModel";
import { BudgetModel } from "../models/BudgetModel";
import { ManageBudgetItemModel } from "../models/ManageBudgetItemModel";
import { CreateBudgetItemModel } from "../models/CreateBudgetItemModel";
import { CreateResponseModel } from "../../../shared/models/CreateResponseModel";
import ConfirmDeleteDialog from "../../../shared/components/ConfirmDeleteDialog/ConfirmDeleteDialog";
import { UpdateResponseModel } from "../../../shared/models/UpdateResponseModel";
import ActionAlert, {
  State,
} from "../../../shared/components/ActionAlert/ActionAlert";

export default function ManageBudget() {
  const { budgetId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [budgetCategoryTypes, setBudgetCategoryTypes] = useState<
    SelectListItemModel[]
  >([]);
  const [sources, setSources] = useState<SelectListItemModel[]>([]);
  const [sourceDefault, setSourceDefault] = useState<string>("--");
  const [manageBudget, setManageBudget] = useState<ManageBudgetModel>(
    new ManageBudgetModel()
  );
  const [header, setHeader] = useState<BudgetModel>(new BudgetModel());
  const [incomeItems, setIncomeItems] = useState<ManageBudgetItemModel[]>([]);
  const [totalIncome, setTotalIncome] = useState<number>(0);
  const [expenseItems, setExpenseItems] = useState<ManageBudgetItemModel[]>([]);
  const [totalExpenses, setTotalExpenses] = useState<number>(0);
  const [difference, setDifference] = useState<number>(0);
  const [isNegative, setIsNegative] = useState<boolean>(false);
  const [createBudgetItem, setCreateBudgetItem] =
    useState<CreateBudgetItemModel>(new CreateBudgetItemModel());
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteBudgetItem, setDeleteBudgetItem] =
    useState<ManageBudgetItemModel>(new ManageBudgetItemModel());
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: "",
  });

  const handleResponse = (data: any, callback: Function) => {
    if (typeof data === "string") {
      setActionAlertState({
        open: true,
        messages: [data],
        severity: "error",
      });
    } else {
      callback(data);
    }
  };

  const getBudgetCatgegoryTypeSelectList = async () => {
    const data = await getBudgetCategoryTypes();
    await handleResponse(data, setBudgetCategoryTypes);
  };

  const setAccountList = async (callback: Function) => {
    const data = await getAccountSelectList();
    await handleResponse(data, callback);
  };

  const getIncomeList = async (callback: Function) => {
    const data = await getIncomeSelectList();
    await handleResponse(data, callback);
  };

  const getMerchantList = async (callback: Function) => {
    const data = await getMerchantSelectList();
    await handleResponse(data, callback);
  };

  const setSourceInstitutions = async (institutionTypeId: string) => {
    switch (Number(institutionTypeId)) {
      case 1:
        setSourceDefault("Select Account...");
        await setAccountList(setSources);
        break;
      case 2:
        setSourceDefault("Select Merchant...");
        await getMerchantList(setSources);
        break;
      case 3:
        setSourceDefault("Select Income...");
        await getIncomeList(setSources);
        break;
    }
  };

  const getManageBudgetById = async () => {
    const data = await getManageBudget(budgetId);
    await handleResponse(data, setManageBudget);

    let model: ManageBudgetModel = data as ManageBudgetModel;
    setHeader(model.budget);

    setIncomeItems(
      model.detail.budgetItems.filter((x) => x.budgetCategoryTypeId === 3)
    );
    setExpenseItems(
      model.detail.budgetItems.filter((x) => x.budgetCategoryTypeId !== 3)
    );

    let income =
      model.detail.payments +
      model.detail.budgetItems
        .filter((x) => x.budgetCategoryTypeId === 3)
        .reduce((sum, current) => sum + current.amount, 0);
    setTotalIncome(income);

    let expenses = model.detail.budgetItems
      .filter((x) => x.budgetCategoryTypeId !== 3)
      .reduce((sum, current) => sum + current.amount, 0);
    setTotalExpenses(expenses);

    const dif = income - expenses;
    setDifference(Math.abs(dif));
    setIsNegative(dif < 0);
    setIsLoading(false);
  };

  const create = async () => {
    const createResult = (result: CreateResponseModel) => {
      if (result.errors.length > 0) {
        setActionAlertState({
          open: true,
          messages: result.errors,
          severity: "error",
        });
      } else {
        setActionAlertState({
          open: true,
          messages: [result.success],
          severity: "success",
        });
        getManageBudgetById();
      }
      setIsSubmitting(false);
    };
    let amount = String(createBudgetItem.amount);
    if (amount.includes(",")) {
      amount = amount.replace(",", "");
      createBudgetItem.amount = Number(amount);
    }
    createBudgetItem.budgetId = Number(budgetId);
    createBudgetItem.institutionTypeId = Number(
      createBudgetItem.budgetCategoryTypeId
    );
    await createBudgetEntry(createBudgetItem, createResult);
  };

  const deleteByBudgetItemId = async () => {
    const deleteResult = (result: UpdateResponseModel) => {
      if (result.errors.length > 0) {
        setActionAlertState({
          open: true,
          messages: result.errors,
          severity: "error",
        });
      } else {
        setActionAlertState({
          open: true,
          messages: [result.success],
          severity: "success",
        });
        getManageBudgetById();
      }
      setIsSubmitting(false);
    };
    await deleteBudgetEntry(deleteBudgetItem, deleteResult);
  };

  const handleUpdateValue = (name, value) => {
    setCreateBudgetItem((x) => ({ ...x, [name]: value }));
  };

  const handleInputChange = (e) => {
    handleUpdateValue(e.target.name, e.target.value);
  };

  const handleBudgetCategoryChange = (e) => {
    setSourceInstitutions(e.target.value);
    handleUpdateValue(e.target.name, e.target.value);
  };

  useEffect(() => {
    getBudgetCatgegoryTypeSelectList();
    getManageBudgetById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await create();
  };

  const handleReceive = (data: ManageBudgetItemModel) => {
    navigate(
      `/budgets/${data.budgetId}/budget-items/${data.budgetItemId}/receive-income/${data.accountId}`
    );
  };

  const handlePay = (data: ManageBudgetItemModel) => {
    if (data.institutionTypeId === 1) {
      navigate(
        `/budgets/${data.budgetId}/budget-items/${data.budgetItemId}/pay-account/${data.accountId}`
      );
    } else {
      navigate(
        `/budgets/${data.budgetId}/budget-items/${data.budgetItemId}/pay-merchant/${data.accountId}`
      );
    }
  };

  const handleOpenDeleteDialog = (data: ManageBudgetItemModel) => {
    if (data) {
      setDeleteBudgetItem(data);
      setOpenDeleteDialog(true);
    }
  };

  const handleGoToInstitution = (data: ManageBudgetItemModel) => {
    let institutionType = "";
    if (data.institutionTypeId === 1) {
      institutionType = "accounts";
    } else if (data.institutionTypeId === 2) {
      institutionType = "merchants";
    } else {
      institutionType = "income";
    }
    navigate(`/${institutionType}/${data.institutionId}/transactions`);
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = async () => {
    handleCancelDelete();
    if (deleteBudgetItem) {
      setIsSubmitting(true);
      await deleteByBudgetItemId();
    }
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
  };

  return (
    <React.Fragment>
      <h4>
        <strong>Manage</strong> Budget for <strong>{header.name}</strong>
      </h4>
      <div className='list-header'>
        <Button
          className='mui-button'
          variant='outlined'
          color='success'
          size='medium'
          onClick={() => navigate("/budgets")}
        >
          Budgets
        </Button>
        <Button
          className='mui-button'
          variant='outlined'
          size='medium'
          onClick={() => navigate(`/budgets/${budgetId}`)}
        >
          Details
        </Button>
      </div>
      <div className='linear-progress-container'>
        {isSubmitting && <LinearProgress color='success' />}
      </div>
      <div className='scrollable-content-dashboard'>
        <div className='enter-budget-item-container'>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Add Budget Item</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='form-container'>
                <form onSubmit={handleSubmit}>
                  <div className='form-input-container'>
                    <select
                      id='budgetCategoryTypeId'
                      name='budgetCategoryTypeId'
                      value={createBudgetItem.budgetCategoryTypeId}
                      onChange={handleBudgetCategoryChange}
                      required
                    >
                      <option key='0' value={""} disabled={true}>
                        Select Budget Category...
                      </option>
                      {budgetCategoryTypes.map((x) => (
                        <option key={x.id} value={x.id}>
                          {x.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='form-input-container'>
                    <select
                      id='institutionId'
                      name='institutionId'
                      value={createBudgetItem.institutionId}
                      onChange={handleInputChange}
                      required
                    >
                      <option key='0' value={""} disabled={true}>
                        {sourceDefault}
                      </option>
                      {sources.map((x) => (
                        <option key={x.id} value={x.id}>
                          {x.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='form-input-container'>
                    <NumberFormat
                      key='amount'
                      name='amount'
                      defaultValue={createBudgetItem.amount}
                      className='value'
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={"input"}
                      onChange={handleInputChange}
                      required={true}
                      min={0}
                    />
                  </div>
                  <div className='form-input-container'>
                    <input
                      key='dueDate'
                      name='dueDate'
                      type='date'
                      placeholder='Due Date'
                      autoComplete='off'
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className='enter-budget-item-form-buttons-container'>
                    <Button
                      className='mui-button'
                      variant='outlined'
                      color='success'
                      size='medium'
                      type='submit'
                    >
                      {!isSubmitting && <span>Submit</span>}
                      {isSubmitting && (
                        <CircularProgress color='success' size={24} />
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className='budget-totals-section'>
          <div className='budget-totals-container'>
            <div className='budget-totals-title'>Income</div>
            <div className='budget-totals-amount'>
              <NumberFormat
                key='amount'
                name='totalIncome'
                value={totalIncome}
                className='value'
                prefix={"$"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
              />
            </div>
          </div>
          <div className='budget-totals-container'>
            <div className='budget-totals-title'>Expenses</div>
            <div className='budget-totals-amount'>
              <NumberFormat
                key='amount'
                name='totalExpenses'
                value={totalExpenses}
                className='value'
                prefix={"$"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
              />
            </div>
          </div>
          <div className='budget-totals-container'>
            <div className='budget-totals-title'>Difference</div>
            <div className='budget-totals-amount'>
              <NumberFormat
                key='amount'
                name='difference'
                value={difference}
                className={isNegative ? "negative-number" : "positive-number"}
                prefix={"$"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
              />
            </div>
          </div>
        </div>
        <div className='budget-income-container'>
          <h4>
            <strong>Income</strong>
          </h4>
          {isLoading && (
            <div className='budget-empty-list'>
              Getting income...
              <br />
              <br />
              <CircularProgress color='success' />
            </div>
          )}
          {!isLoading && incomeItems.length === 0 && (
            <div className='budget-empty-list'>
              <h4>No income found</h4>
            </div>
          )}
          {!isLoading && incomeItems.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th className='budget-list-item name'></th>
                  <th className='budget-list-item-right amount'>Budgeted</th>
                  <th className='budget-list-item-right amount'>Actual</th>
                  <th className='budget-list-item-right date hide'>Date</th>
                  <th className='budget-list-item-right actions'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {incomeItems.map((row) => (
                  <tr key={row.budgetItemId}>
                    <td
                      className='budget-list-item name goto'
                      onClick={() => handleGoToInstitution(row)}
                    >
                      {row.institutionName}
                    </td>
                    <td className='budget-list-item-right amount'>
                      <NumberFormat
                        value={row.amount}
                        prefix={"$"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                      />
                    </td>
                    <td className='budget-list-item-right amount'>
                      <NumberFormat
                        value={row.actualAmount}
                        prefix={"$"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                      />
                    </td>
                    <td className='budget-list-item-right hide'>
                      {row.dueDate}
                    </td>
                    <td className='budget-list-item-right actions budget-actions'>
                      <Button
                        variant='text'
                        onClick={() => handleReceive(row)}
                        color='success'
                        size='small'
                        sx={{ fontSize: 15 }}
                      >
                        <MdOutlineAdd />
                      </Button>
                      <Button
                        variant='text'
                        onClick={() => handleOpenDeleteDialog(row)}
                        color='error'
                        size='small'
                        sx={{ fontSize: 14 }}
                      >
                        <MdClear />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className='budget-expenses-container'>
          <h4>
            <strong>Expenses</strong>
          </h4>
          {isLoading && (
            <div className='budget-empty-list'>
              Getting expenses...
              <br />
              <br />
              <CircularProgress color='success' />
            </div>
          )}
          {!isLoading && expenseItems.length === 0 && (
            <div className='budget-empty-list'>
              <h4>No expenses found</h4>
            </div>
          )}
          {!isLoading && expenseItems.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th className='budget-list-item name'></th>
                  <th className='budget-list-item-right amount'>Budgeted</th>
                  <th className='budget-list-item-right amount'>Actual</th>
                  <th className='budget-list-item-right date hide'>Date</th>
                  <th className='budget-list-item-right actions'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {expenseItems.map((row) => (
                  <tr key={row.budgetItemId}>
                    {row.url !== null && (
                      <td className='budget-list-item name goto'>
                        <a href={row.url} target='_blank' rel='noreferrer'>
                          {row.institutionName}
                        </a>
                      </td>
                    )}
                    {row.url === null && (
                      <td className='budget-list-item name'>
                        {row.institutionName}
                      </td>
                    )}
                    <td className='budget-list-item-right amount'>
                      <NumberFormat
                        value={row.amount}
                        prefix={"$"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                      />
                    </td>
                    <td className='budget-list-item-right amount'>
                      <NumberFormat
                        value={row.actualAmount}
                        prefix={"$"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                      />
                    </td>
                    <td className='budget-list-item-right hide'>
                      {row.dueDate}
                    </td>
                    <td className='budget-list-item-right actions budget-actions'>
                      <Button
                        variant='text'
                        onClick={() => handlePay(row)}
                        color='success'
                        size='small'
                        sx={{ fontSize: 15 }}
                      >
                        <MdOutlineAdd />
                      </Button>
                      <Button
                        variant='text'
                        onClick={() => handleOpenDeleteDialog(row)}
                        color='error'
                        size='small'
                        sx={{ fontSize: 14 }}
                      >
                        <MdClear />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <ConfirmDeleteDialog
        title={"Confirm Delete Budget Item"}
        text={"Deleting this budget item cannot be undone. Continue?"}
        openDialog={openDeleteDialog}
        handleCancel={handleCancelDelete}
        handleDelete={handleDelete}
      />
      <ActionAlert
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
      />
    </React.Fragment>
  );
}
