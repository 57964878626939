import configuration from '../../../../api-config.json';
import { POST, GET, PUT, DELETE } from '../../../../shared/services/apiRequests';
import { PayIncomeModel } from '../models/PayIncomeModel';
import { CreateResponseModel } from '../../../../shared/models/CreateResponseModel';
import { UpdateIncomePaymentModel } from '../models/UpdateIncomePaymentModel';
import { UpdateResponseModel } from '../../../../shared/models/UpdateResponseModel';
import { IncomeTransactionsModel } from '../models/IncomeTransactionsModel';
import { LedgerTransactionModel } from '../../../../shared/models/LedgerTransactionModel';

const baseUrl = `${configuration.API_URL}/income`;
const routeVersion1 = '/v1';

export const payIncome = async (data: PayIncomeModel, handleResult: Function): Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/transactions/pay';
  const successMessage = 'Payment saved successfully!';
  await POST<PayIncomeModel>(data, url, successMessage, true, handleResult);
}

export const getTransaction = async (id: string | undefined, transactionId: string | undefined): Promise<UpdateIncomePaymentModel | any> =>  {
  const url = baseUrl + routeVersion1 + '/' + id + '/transactions/' + transactionId;
  return await GET<UpdateIncomePaymentModel>(url, true);
}

export const getTransactions = async (id: string | undefined): Promise<IncomeTransactionsModel | any> =>  {
  const url = baseUrl + routeVersion1 + '/' + id + '/transactions';
  return await GET<IncomeTransactionsModel>(url, true);
}

export const updatePayment = async (data: UpdateIncomePaymentModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/' +  data?.sourceInstitutionId + '/transactions/' + data?.ledgerTransactionId;
  const successMessage = 'Payment updated successfully!';
  await PUT<UpdateIncomePaymentModel>(data, url, successMessage, true, handleResult);
}

export const deleteTransaction = async (id: string | undefined, data: LedgerTransactionModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/' + id + '/transactions/' + data.ledgerTransactionId;
  const successMessage = 'Transaction was deleted successfully!';
  await DELETE(url, successMessage, true, handleResult);
}
