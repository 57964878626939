import React, { useState, useEffect } from "react";

import Overview from "./Overview/Overview";
import Forecast from "./Forecast/Forecast";
import ActionAlert, {
  State,
} from "../../shared/components/ActionAlert/ActionAlert";

export default function Dashboard() {
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: "",
  });

  const handleResponse = (data: any, setDatasource: Function) => {
    if (typeof data === "string") {
      setActionAlertState({
        open: true,
        messages: [data],
        severity: "error",
      });
    } else {
      setDatasource(data);
    }
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
  };

  return (
    <React.Fragment>
      <div className='scrollable-content-dashboard'>
        <Overview handleResponse={handleResponse} />
        <Forecast handleResponse={handleResponse} />
        <ActionAlert
          open={actionAlertState.open}
          messages={actionAlertState.messages}
          severity={actionAlertState.severity}
          handleClose={handleActionAlertClose}
        />
      </div>
    </React.Fragment>
  );
}
