import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MdOutlineAdd } from 'react-icons/md';
import Button from '@mui/material/Button';
import NumberFormat from 'react-number-format';
import CircularProgress from '@mui/material/CircularProgress';

import '../../../../assets/css/form.css';
import { getBudgetItem } from '../../../Budgets/services/requests';
import { createIncome, getIncomeSelectList } from '../../services/requests';
import { getAccountSelectList } from '../../../Accounts/services/requests';
import { payIncome } from '../services/requests';
import { ManageBudgetItemModel } from '../../../Budgets/models/ManageBudgetItemModel';
import { SelectListItemModel } from '../../../../shared/models/SelectListItemModel';
import { CreateIncomeModel } from '../../models/CreateIncomeModel';
import { PayIncomeModel } from '../models/PayIncomeModel';
import CreateIncomeDialog from './CreateIncomeDialog';
import { CreateResponseModel } from '../../../../shared/models/CreateResponseModel';
import { PrimaryAccountBalanceModel } from '../../../Dashboard/models/PrimaryAccountBalanceModel';
import ActionAlert, { State } from '../../../../shared/components/ActionAlert/ActionAlert';

export default function PayIncome() {

  const { budgetId } = useParams();
  const { budgetItemId } = useParams();
  const { incomeId } = useParams();
  const { accountId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [incomes, setIncomes] = useState<SelectListItemModel[]>([]);
  const [accounts, setAccounts] = useState<SelectListItemModel[]>([]);
  const [payment, setPayment] = useState<PayIncomeModel>(new PayIncomeModel());
  const [hasError, setHasError] = useState<boolean>(false);
  const [shouldNavigate, setShouldNavigate] = useState<boolean>(true);
  const [openCreateIncomeDialog, setOpenCreateIncomeDialog] = useState(false);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
  });

  const handleResponse = (data: any, callback: Function)=> {
    if(typeof data === 'string') {
      setActionAlertState({ 
        open: true, 
        messages: [ data ], 
        severity: 'error'
      });
    } else {
      callback(data);
    }
  }

  const setBudgetItemAsPayment = (data: ManageBudgetItemModel) => {
    let payIncome = new PayIncomeModel();
    payIncome.incomeId = data.institutionId;
    payIncome.accountId = data.accountId;
    payIncome.amount = data.amount;
    if(data.dueDate !== null) {
      payIncome.date = data.dueDateReverseFormat as string;
    }
    setPayment(payIncome);
  }

  const setNewSelectedIncome = async (data: any) => {
    setIncomes(data);
    const newIncomeId = data.reduce((maxId, m) => Math.max(maxId, m.id), 0);
    if(newIncomeId !== 0) {
      handleUpdateValue('incomeId', newIncomeId);
    }
  }

  const getIncomes = async (isCreating: boolean = false) => {
    const data = await getIncomeSelectList();
    if(isCreating) {
      await handleResponse(data, setNewSelectedIncome);
    }
    else {
      await handleResponse(data, setIncomes);
    }
  }

  const getAccounts = async () => {
    const data = await getAccountSelectList();
    await handleResponse(data, setAccounts);
  }

  const getBudgetItemById = async () => {
    const data = await getBudgetItem(budgetId, budgetItemId);
    await handleResponse(data, setBudgetItemAsPayment);
  }

  const createNewIncome = async (income: CreateIncomeModel) => {
    const createResult = (result: CreateResponseModel) => {
      if(result.errors.length > 0) {
        setShouldNavigate(false);
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setShouldNavigate(false);
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
        getIncomes(true);
      }
      setIsSubmitting(false);
    }
    await createIncome(income, createResult);
  }

  const pay = async () => {
    const createResult = (result: CreateResponseModel) => {
      if(result.errors.length > 0) {
        setHasError(true);
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setHasError(false);
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
      }
      setIsSubmitting(false);
    }
    await payIncome(payment, createResult);
  }

  const navigateBack = () => {
    if(budgetId) {
      navigate(`/budgets/${budgetId}/manage`);
    } else if (incomeId) {
      navigate(`/income/${incomeId}/transactions`);
    } else if(accountId) {
      navigate(`/accounts/${accountId}/transactions`);
    } else {
      navigate('/');
    }
  }

  const handleUpdateValue = (name, value) => {
    if(name === 'amount') {
      value = value.replace(',', '');
    }
    setPayment(x => ({...x, [name]: value}));
  }

  const handleInputChange = (e) => {
    handleUpdateValue(e.target.name, e.target.value);
  }

  useEffect(() => {
    getIncomes();
    getAccounts();
    if(budgetId !== undefined && budgetItemId !== undefined) {
      getBudgetItemById();
    } else {
      handleUpdateValue('incomeId', incomeId);
      handleUpdateValue('accountId', accountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenCreateIncomeDialog = () => {
    setOpenCreateIncomeDialog(true);
  };

  const handleCancelCreateIncome = () => {
    setOpenCreateIncomeDialog(false);
  };
 
  const handleCreateIncome = async (income: CreateIncomeModel) => {
    handleCancelCreateIncome();
    setIsSubmitting(true);
    await createNewIncome(income);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await pay();
  };

  const handleCancel = () => {
    navigateBack();
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
    if(!hasError && shouldNavigate) {
      navigateBack();
    }
  };

  return (
    <React.Fragment>
      <h4><strong>Pay</strong> Income</h4>
      <div className='form-container'>
        <form onSubmit={handleSubmit}>
          <div className='form-input-container'>
            <select 
              id='incomeId' 
              name='incomeId' 
              value={payment.incomeId} 
              className='editable-select-list'
              onChange={handleInputChange}
              required
            >
              <option key='0' value={''} disabled={true}>Select Income Source...</option>
              {incomes.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
            </select>            
            <span className='add-entity-container'>
              <Button 
                variant='text' 
                onClick={() => handleOpenCreateIncomeDialog()} 
                color='success'
                size='small'
              >
                <MdOutlineAdd />
              </Button>
            </span>
          </div>
          <div className='form-input-container'>
            <select 
              id='accountId' 
              name='accountId' 
              value={payment.accountId} 
              onChange={handleInputChange}
              required
            >
              <option key='0' value={''} disabled={true}>Select Account...</option>
              {accounts.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
            </select>
          </div>
          <div className='form-input-container'>
          <NumberFormat
              key='amount'
              name='amount'
              value={payment.amount}
              className='value'
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'input'}
              onChange={handleInputChange} 
              required={true}
              min={0}
            />
          </div>
          <div className='form-input-container'>
            <input 
              key='date'
              name='date' 
              type='date' 
              placeholder='Date' 
              autoComplete='off' 
              defaultValue={payment.date as string | undefined}
              onChange={handleInputChange} 
            />
          </div>
          <div className='form-input-container'>
            <textarea 
              key='notes' 
              name='notes' 
              placeholder='Notes' 
              autoComplete='off' 
              onChange={handleInputChange} 
              maxLength={1000} 
            />
          </div>
          <div className='form-buttons-container'>
            <Button 
              className='mui-button' 
              variant='outlined' 
              color='error' 
              size='medium' 
              onClick={handleCancel}>
              Cancel
            </Button>
            <Button 
              className='mui-button' 
              variant='outlined' 
              color='success' 
              size='medium' 
              type='submit'>
              {!isSubmitting && (
                <span>Submit</span>
              )}
              {isSubmitting && (
                <CircularProgress 
                  color='success' 
                  size={24}
                />
              )}
            </Button>
          </div>
        </form>
      </div>
      <CreateIncomeDialog 
        openDialog={openCreateIncomeDialog} 
        handleResponse={handleResponse}
        handleCancel={handleCancelCreateIncome} 
        handleCreateIncome={handleCreateIncome} 
      />
      <ActionAlert 
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
      />
    </React.Fragment>
  );
}