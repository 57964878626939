import React from 'react';

const ActionAlertMessage = ({
  messages
}) => {

  return (
    <React.Fragment>
      {messages.map(message => (
        <p key={message}>{message}</p>
      ))}
    </React.Fragment>

  );
}

export default ActionAlertMessage;