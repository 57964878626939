import './desktop.css';
import ClickableWidget from '../Widget/ClickableWidget';

const Desktop = ({
  primaryAccountBalance,
  creditTotals,
  handleGoToPrimaryAccount,
  handleGoToCreditAccounts
}) => {
  
  return (
    <div className='desktop-container'>
      <ClickableWidget
        title={primaryAccountBalance.accountName}
        amount={primaryAccountBalance.balance}
        borderColor={'#2596be'}
        backgroundColor={'#d3eaf2'}
        handleOnClick={() => handleGoToPrimaryAccount(primaryAccountBalance.accountId)}
        showAsNegative={primaryAccountBalance.balance < 0 ? true : false}
      />
      <ClickableWidget
        title={'Total Credit'}
        amount={creditTotals.totalCredit}
        borderColor={'#49be25'}
        backgroundColor={'#dbf2d3'}
        handleOnClick={() => handleGoToCreditAccounts('limit')}
      />
      <ClickableWidget
        title={'Credit Balance'}
        amount={creditTotals.totalCreditBalance}
        borderColor={'#be4d25'}
        backgroundColor={'#f2dbd3'}
        handleOnClick={() => handleGoToCreditAccounts('balance')}
        showAsNegative={creditTotals?.totalCreditBalance > 0 ? true : false}
      />
      <ClickableWidget
        title={'Credit Utilization'}
        amount={creditTotals.totalCreditUtilization}
        borderColor={'#9925be'}
        backgroundColor={'#ebd3f2'}
        handleOnClick={() => handleGoToCreditAccounts('used')}
        prefix={''}
        suffix={'%'}
      />
    </div> 
  )
}

export default Desktop;
