import React, { memo, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import '../../../../assets/css/dialog.css';
import { getMerchantTypes } from '../../services/requests';
import { SelectListItemModel } from '../../../../shared/models/SelectListItemModel';
import { CreateMerchantModel } from '../../models/CreateMerchantModel';

const CreateMerchantDialog = ({
  openDialog,
  handleResponse,
  handleCancel,
  handleCreateMerchant
}) => {

  const [merchantTypes, setMerchantTypes] = useState<SelectListItemModel[]>([]);
  const [merchant, setMerchant] = useState<CreateMerchantModel>(new CreateMerchantModel());

  const getMerchantTypeSelectList = async () => {
    const data = await getMerchantTypes();
    await handleResponse(data, setMerchantTypes);
  }

  const handleUpdateValue = (name, value) => {
    setMerchant(x => ({...x, [name]: value}));
  }

  const handleInputChange = (e) => {
    handleUpdateValue(e.target.name, e.target.value);
  }

  useEffect(() => {
    getMerchantTypeSelectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleCreateMerchant(merchant);
  };

  return (
    <React.Fragment>
      <Dialog open={openDialog}>
        <DialogTitle>
        </DialogTitle>
        <DialogContent>
          Create New Merchant
        </DialogContent>
        <div className='dialog-form-container'>
          <form onSubmit={handleSubmit}>
            <div className='dialog-form-input-container'>
              <select 
                id='merchantTypeId' 
                name='merchantTypeId' 
                value={merchant.merchantTypeId} 
                onChange={handleInputChange}
                required
              >
              <option key='0' value={''} disabled={true}>Select Merchant Type...</option>
                {merchantTypes.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
              </select>
            </div>
            <div className='dialog-form-input-container'>
              <input 
                key='name' 
                name='name' 
                type='text' 
                placeholder='Merchant Name' 
                autoComplete='off' 
                onChange={handleInputChange} 
                required 
                minLength={3} 
                maxLength={50} 
              />
            </div>
            <DialogActions>
              <div className="dialog-action-container">
                <Button 
                  className='mui-button'
                  variant='outlined' 
                  color='error' 
                  size='medium'
                  onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  className='mui-button'
                  variant='outlined' 
                  color='success' 
                  size='medium'
                  type='submit'>
                  Submit
                </Button>
              </div>
            </DialogActions>
        </form>
      </div>
      </Dialog>
    </React.Fragment>
  );
}

export default memo(CreateMerchantDialog);