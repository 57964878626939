import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import '../../../assets/css/form.css';
import { getUser, updateUser } from '../services/requests';
import { getUser as getUserFromCache, setUser as updateCachedUser } from '../../../shared/services/localStorageService';
import { UpdateUserModel } from '../models/UpdateUserModel';
import { UpdateResponseModel } from '../../../shared/models/UpdateResponseModel';
import ActionAlert, { State } from '../../../shared/components/ActionAlert/ActionAlert';

const UpdateUser = ({
  handleUpdateUser
}) => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [user, setUser] = useState<UpdateUserModel>({});
  const [hasError, setHasError] = useState<boolean>(false);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
  });

  const handleResponse = (data: any, callback: Function)=> {
    if(typeof data === 'string') {
      setActionAlertState({ 
        open: true, 
        messages: [ data ], 
        severity: 'error'
      });
    } else {
      callback(data);
    }
  }

  const getUserById = async () => {
    const data = await getUser();
    await handleResponse(data, setUser);
    setIsLoading(false);
  }

  const updateCurrentUser = (user: UpdateUserModel) => {
    const currentUser = getUserFromCache();
    currentUser.email = user.email as string;
    currentUser.firstName = user.firstName as string;
    currentUser.lastName = user.lastName as string;
    updateCachedUser(currentUser);
    handleUpdateUser(currentUser);
  }

  const update = async () => {
    const updateResult = (result: UpdateResponseModel) => {
      if(result.errors.length > 0) {
        setHasError(true);
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        updateCurrentUser(user);
        setHasError(false);
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
      }
      setIsSubmitting(false);
    }
    await updateUser(user, updateResult);
  }

  const handleUpdateValue = (name, value) => {
    setUser(x => ({...x, [name]: value}));
  }

  const handleInputChange = (e) => {
    handleUpdateValue(e.target.name, e.target.value);
  }

  useEffect(() => {
    getUserById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await update();
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
    if(!hasError) {
      navigate('/users');
    }
  };

  return (
    <React.Fragment>
      <h4><strong>Update</strong> User</h4>
      {isLoading && (
        <div className='empty-list'>
          Getting your user information...
          <br />
          <br />
          <CircularProgress color='success'/>
        </div>
      )}
      {!isLoading && (
        <div className='form-container'>
          <form onSubmit={handleSubmit}>
            <div className='form-input-container'>
              <input 
                key='email' 
                name='email' 
                type='text' 
                placeholder='Email' 
                autoComplete='off' 
                autoCapitalize='off'
                defaultValue={user.email} 
                onChange={handleInputChange} 
                required 
                minLength={5} 
                maxLength={50} 
              />
            </div>
            <div className='form-input-container'>
              <input 
                key='firstName' 
                name='firstName' 
                type='text' 
                placeholder='First Name' 
                autoComplete='off' 
                defaultValue={user.firstName} 
                onChange={handleInputChange} 
                required 
                minLength={2} 
                maxLength={50} 
              />
            </div>
            <div className='form-input-container'>
              <input 
                key='lastName' 
                name='lastName' 
                type='text' 
                placeholder='Last Name' 
                autoComplete='off' 
                defaultValue={user.lastName} 
                onChange={handleInputChange} 
                required 
                minLength={2} 
                maxLength={50} 
              />
            </div>
            <div className='form-buttons-container'>
              <Button 
                className='auth-action-button' 
                variant='outlined' 
                color='success' 
                size='medium' 
                type='submit'>
                {!isSubmitting && (
                  <span>Submit</span>
                )}
                {isSubmitting && (
                  <CircularProgress 
                    color='success' 
                    size={24}
                  />
                )}
              </Button>
            </div>
          </form>
        </div>
      )}
      <ActionAlert 
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
      />
    </React.Fragment>
  )
}

export default UpdateUser;