import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import '../../../assets/css/form.css';
import { getIncomeTypes, createIncome } from '../services/requests';
import { SelectListItemModel } from '../../../shared/models/SelectListItemModel';
import { CreateIncomeModel } from '../models/CreateIncomeModel';
import { CreateResponseModel } from '../../../shared/models/CreateResponseModel';
import ActionAlert, { State } from '../../../shared/components/ActionAlert/ActionAlert';

export default function CreateIncome() {

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [incomeTypes, setIncomeTypes] = useState<SelectListItemModel[]>([]);
  const [income, setIncome] = useState<CreateIncomeModel>(new CreateIncomeModel());
  const [hasError, setHasError] = useState<boolean>(false);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
  });

  const handleResponse = (data: any, callback: Function)=> {
    if(typeof data === 'string') {
      setActionAlertState({ 
        open: true, 
        messages: [ data ], 
        severity: 'error'
      });
    } else {
      callback(data);
    }
  }

  const getIncomeTypeSelectList = async () => {
    const data = await getIncomeTypes();
    await handleResponse(data, setIncomeTypes);
  }

  const create = async () => {
    const createResult = (result: CreateResponseModel) => {
      if(result.errors.length > 0) {
        setHasError(true);
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setHasError(false);
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
      }
      setIsSubmitting(false);
    }
    await createIncome(income, createResult);
  }

  const handleUpdateValue = (name, value) => {
    setIncome(x => ({...x, [name]: value}));
  }

  const handleInputChange = (e) => {
    handleUpdateValue(e.target.name, e.target.value);
  }

  useEffect(() => {
    getIncomeTypeSelectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await create();
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
    if(!hasError) {
      navigate('/income');
    }
  };

  return (
    <React.Fragment>
      <h4><strong>Create</strong> Income Source</h4>
      <div className='form-container'>
        <form onSubmit={handleSubmit}>
          <div className='form-input-container'>
            <select 
              id='incomeTypeId' 
              name='incomeTypeId' 
              value={income.incomeTypeId} 
              onChange={handleInputChange}
              required
            >
              <option key='0' value={''} disabled={true}>Select Income Type...</option>
              {incomeTypes.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
            </select>
          </div>
          <div className='form-input-container'>
            <input 
              key='name' 
              name='name' 
              type='text' 
              placeholder='Income Name' 
              autoComplete='off' 
              onChange={handleInputChange} 
              required 
              minLength={3} 
              maxLength={50} 
            />
          </div>
          <div className='form-buttons-container'>
            <Button 
              className='mui-button' 
              variant='outlined' 
              color='error' 
              size='medium' 
              onClick={() => navigate('/income')}>
              Cancel
            </Button>
            <Button 
              className='mui-button' 
              variant='outlined' 
              color='success' 
              size='medium' 
              type='submit'>
              {!isSubmitting && (
                <span>Submit</span>
              )}
              {isSubmitting && (
                <CircularProgress 
                  color='success' 
                  size={24}
                />
              )}
            </Button>
          </div>
        </form>
      </div>
      <ActionAlert 
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
      />
    </React.Fragment>
  );
}