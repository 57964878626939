import React from 'react';
import NumberFormat from 'react-number-format';

const CurrentMonthForecast = ({
  currentMonth,
  nextMonth,
  forecast,
  handleGoToBudget
}) => {

  return (
    <React.Fragment>
      <div 
        className='month-totals-section'
        onClick={() => handleGoToBudget(forecast.budgetId)}
      >
        <h3><strong>{currentMonth}</strong></h3>
        <div className='month-totals-container'>
          <div className='month-totals-title'>
            <em>Income So Far</em>
          </div>
        </div>
        <div className='month-totals-container'>
          <div className='month-totals-subtitle'>
            Budgeted
          </div>
          <div className='month-totals-amount'>
            <NumberFormat
              key='budgetedIncomeReceived'
              name='budgetedIncomeReceived'
              value={forecast.budgetedIncomeReceived}
              className={forecast.budgetedIncomeReceived < 0 ? 'negative-number' : 'inherit'}
              prefix={'$'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'text'}
            />
          </div>
        </div>
        <div className='month-totals-container'>
          <div className='month-totals-subtitle'>
            Unplanned
          </div>
          <div className='month-totals-amount'>
            <NumberFormat
              key='unplannedIncomeReceived'
              name='unplannedIncomeReceived'
              value={forecast.unplannedIncomeReceived}
              className={forecast.unplannedIncomeReceived < 0 ? 'negative-number' : 'inherit'}
              prefix={'$'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'text'}
            />
          </div>
        </div>
        <div className='month-totals-container'>
          <div className='month-totals-title'>
            <em>Expenses So Far</em>
          </div>
        </div>
        <div className='month-totals-container'>
          <div className='month-totals-subtitle'>
            Budgeted
          </div>
          <div className='month-totals-amount'>
            <NumberFormat
              key='budgetedExpensesPaid'
              name='budgetedExpensesPaid'
              value={forecast.budgetExpensesPaid}
              className='negative-number'
              prefix={'$'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'text'}
            />
          </div>
        </div>
        <div className='month-totals-container'>
          <div className='month-totals-subtitle'>
            Unplanned
          </div>
          <div className='month-totals-amount'>
            <NumberFormat
              key='unplannedExpensesPaid'
              name='unplannedExpensesPaid'
              value={forecast.unplannedExpensesPaid}
              className='negative-number'
              prefix={'$'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'text'}
            />
          </div>
        </div>
        <div className='month-totals-container'>
          <div className='month-totals-title'>
            Current Balance
          </div>
          <div className='month-totals-amount'>
            <NumberFormat
              key='currentBalance'
              name='currentBalance'
              value={forecast.currentBalance}
              className={forecast.currentBalance < 0 ? 'negative-number' : 'inherit'}
              prefix={'$'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'text'}
            />
          </div>
        </div>
        <div className='month-totals-container'>
          <div className='month-totals-title'>
            Upcoming Income
          </div>
          <div className='month-totals-amount'>
            <NumberFormat
              key='upcomingIncome'
              name='upcomingIncome'
              value={forecast.upcomingIncome}
              className={forecast.upcomingIncome < 0 ? 'negative-number' : 'inherit'}
              prefix={'$'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'text'}
            />
          </div>
        </div>
        <div className='month-totals-container'>
          <div className='month-totals-title'>
            Upcoming Expenses
          </div>
          <div className='month-totals-amount'>
            <NumberFormat
              key='upcomingExpenses'
              name='upcomingExpenses'
              value={forecast.upcomingExpenses}
              className='negative-number'
              prefix={'$'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'text'}
            />
          </div>
        </div>
        <hr />
        <div className='month-totals-container'>
          <div className='month-totals-title'>
            Going Into {nextMonth}
          </div>
          <div className='month-totals-amount'>
            <NumberFormat
              key='currentMonthBalance'
              name='currentMonthBalance'
              value={forecast.currentMonthBalance}
              className={forecast.currentMonthBalance < 0 ? 'negative-number' : 'positive-number'}
              prefix={'$'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'text'}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CurrentMonthForecast;