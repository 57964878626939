import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import '../../assets/css/form.css';
import '../../assets/css/auth.css';
import logo from '../../assets/images/logo-large.png';
import { createResetPasswordToken } from '../services/requests';
import { EmailModel } from '../models/EmailModel';
import { CreateResponseModel } from '../../shared/models/CreateResponseModel';
import ActionAlert, { State } from '../../shared/components/ActionAlert/ActionAlert';

export default function ForgotPassword() {

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState<EmailModel>(new EmailModel());
  const [hasError, setHasError] = useState<boolean>(false);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
    autoHideDuration: 0
  });

  const create = async () => {
    const createResult = (result: CreateResponseModel) => {
      if(result.errors.length > 0) {
        setHasError(true);
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setHasError(false);
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
      }
      setIsSubmitting(false);
    }
    await createResetPasswordToken(email, createResult);
  }

  const handleUpdateValue = (name, value) => {
    setEmail(x => ({...x, [name]: value}));
  }

  const handleInputChange = (e) => {
    handleUpdateValue(e.target.name, e.target.value);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await create();
  };


  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
    if(!hasError) {
      navigate('/sign-in');
    }
  };

  return (
    <React.Fragment>
      <div className='auth-container'>
        <div className='auth-action-container'>
          <div className='auth-action-section'>
            <div className='auth-action-logo'>
              <img src={logo} alt='' />
              <span>Ledgers</span>
            </div>
            <div className='auth-action-title'>
              <span>Forgot Your Password?</span>
            </div>
            <div className='auth-action-subtitle'>
              <span>Enter your email to reset your password</span>
            </div>
            <div className='form-container'>
              <form onSubmit={handleSubmit}>
                <div className='form-input-container'>
                  <input 
                    key='email' 
                    name='email' 
                    type='text' 
                    placeholder='Email' 
                    autoComplete='off' 
                    autoCapitalize='off'
                    onChange={handleInputChange} 
                    required 
                  />
                </div>
                <div className='form-buttons-container'>
                  <Button 
                    className='auth-action-button' 
                    variant='outlined' 
                    color='success' 
                    size='medium' 
                    type='submit'>
                    {!isSubmitting && (
                      <span>Reset My Password</span>
                    )}
                    {isSubmitting && (
                      <CircularProgress 
                        color='success' 
                        size={24}
                      />
                    )}
                  </Button>
                </div>
              </form>
            </div>
            <div className='sign-in'>
              Want to sign in?
              <NavLink to='/sign-in' >
                &nbsp;Sign In
              </NavLink> 
            </div>
          </div>
        </div>
        <div className='auth-background-container'>
        </div>
      </div>
      <ActionAlert 
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
        autoHideDuration={actionAlertState.autoHideDuration}
      />
    </React.Fragment>
  )
}
