import React, { useState, useEffect } from 'react';
import { MdTimeline, MdEdit, MdClear } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import '../../../assets/css/list.css';
import { getAccounts, deleteAccount } from '../services/requests';
import { AccountModel } from '../models/AccountModel';
import ConfirmDeleteDialog from '../../../shared/components/ConfirmDeleteDialog/ConfirmDeleteDialog';
import { UpdateResponseModel } from '../../../shared/models/UpdateResponseModel';
import ActionAlert, { State } from '../../../shared/components/ActionAlert/ActionAlert';

export default function ListAccounts() {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accounts, setAccounts] = useState<AccountModel[]>([]);
  const [account, setAccount] = useState<AccountModel>(new AccountModel());
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
  });

  const handleResponse = (data: any, callback: Function)=> {
    if(typeof data === 'string') {
      setActionAlertState({ 
        open: true, 
        messages: [ data ], 
        severity: 'error'
      });
    } else {
      callback(data);
    }
  }

  const getAccountList = async () => {
    const data = await getAccounts();
    await handleResponse(data, setAccounts);
    setIsLoading(false);
  }

  const deleteByAccountId = async () => {
    const deleteResult = (result: UpdateResponseModel) => {
      if(result.errors.length > 0) {
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
        getAccountList();
      }
      setIsSubmitting(false);
    }
    await deleteAccount(account, deleteResult);
  }

  useEffect(() => {
    async function hasAccounts() {
      await getAccountList();
      return accounts && accounts.length > 0;
    }
    hasAccounts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoToInstitution = (account: AccountModel) => {
    navigate(`/accounts/${account.accountId}/transactions`);
  }

  const handleOpenDeleteDialog = (account: AccountModel) => {
    if(account) {
      setAccount(account);
      setOpenDeleteDialog(true);
    }
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = async () => {
    handleCancelDelete();
    if(account) {
      setIsSubmitting(true);
      await deleteByAccountId();
    }
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
  };

  return (
    <React.Fragment>
      <h4>
        <strong>List</strong> Accounts
      </h4>
      <div className='list-header'>
        <Button
          className='mui-button'
          variant='outlined'
          color='success'
          size='medium'
          onClick={() => navigate("/")}
        >
          Dashboard
        </Button>
        <Button
          className='mui-button'
          variant='outlined'
          size='medium'
          onClick={() => navigate("/accounts/create")}
        >
          New
        </Button>
      </div>
      <div className='linear-progress-container'>
        {isSubmitting && <LinearProgress color='success' />}
      </div>
      {isLoading && (
        <div className='empty-list'>
          Getting accounts...
          <br />
          <br />
          <CircularProgress color='success' />
        </div>
      )}
      {!isLoading && accounts?.length === 0 && (
        <div className='empty-list'>
          <h4>No accounts found</h4>
        </div>
      )}
      {accounts?.length > 0 && (
        <table>
          <thead>
            <tr>
              <th className='list-item name'>Name</th>
              <th className='list-item hide'>Type</th>
              <th className='list-item-right actions'>Actions</th>
            </tr>
          </thead>
          <tbody className='scrollable-content'>
            {accounts.map((row) => (
              <tr key={row.accountId}>
                <td
                  className='budget-list-item name goto'
                  onClick={() => handleGoToInstitution(row)}
                >
                  {row.name}
                </td>
                <td className='list-item hide'>{row.accountType}</td>
                <td className='list-item-right actions'>
                  <Button
                    variant='text'
                    onClick={() =>
                      navigate(`/accounts/${row.accountId}/transactions`)
                    }
                    color='inherit'
                  >
                    <MdTimeline />
                  </Button>
                  <Button
                    variant='text'
                    onClick={() => navigate(`/accounts/${row.accountId}`)}
                    color='info'
                  >
                    <MdEdit />
                  </Button>
                  <Button
                    variant='text'
                    onClick={() => handleOpenDeleteDialog(row)}
                    color='error'
                    disabled={row.isPrimary}
                  >
                    <MdClear />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <ConfirmDeleteDialog
        title={"Confirm Delete Account"}
        text={"Deleting this account cannot be undone. Continue?"}
        openDialog={openDeleteDialog}
        handleCancel={handleCancelDelete}
        handleDelete={handleDelete}
      />
      <ActionAlert
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
      />
    </React.Fragment>
  );
}