import { BudgetForecast } from './BudgetForecast';
import { CurrentMonthForecast } from './CurrentMonthForecast';

export class PrimaryAccountForecastModel {
  accountName: string = '';
  currentMonthId: number = 0;
  currentMonth: CurrentMonthForecast = new CurrentMonthForecast();
  futureMonths: BudgetForecast[] = [];
}


