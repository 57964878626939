import React, { useState, useEffect } from 'react';
import { MdTimeline } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import NumberFormat from 'react-number-format';
import CircularProgress from '@mui/material/CircularProgress';

import '../../../assets/css/list.css';
import { getCreditAccounts } from '../services/requests';
import { CreditAccountModel } from '../models/CreditAccountModel';
import ActionAlert, { State } from '../../../shared/components/ActionAlert/ActionAlert';

export default function ListCreditAccounts() {

  const { showColumn } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [creditAccounts, setCreditAccounts] = useState<CreditAccountModel[]>([]);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
  });

  const handleResponse = (data: any, callback: Function)=> {
    if(typeof data === 'string') {
      setActionAlertState({ 
        open: true, 
        messages: [ data ], 
        severity: 'error'
      });
    } else {
      callback(data);
    }
  }
  
  const geCreditAccountList = async () => {
    let data = await getCreditAccounts();
    if (typeof(data) !== 'string' && (showColumn === 'balance' || showColumn === 'used')) {
        data = data as CreditAccountModel[];
        const filteredData: CreditAccountModel[] = data.filter(x => x.currentBalance > 0);
        await handleResponse(filteredData, setCreditAccounts);
    } else {
      await handleResponse(data, setCreditAccounts);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    async function hasAccounts() {
      await geCreditAccountList();
      return creditAccounts && creditAccounts.length > 0;
    }
    hasAccounts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
  };

  return (
    <React.Fragment>
      {(showColumn === "balance" || showColumn === "used") && (
        <h4>
          <strong>List</strong> Credit Accounts With Balances
        </h4>
      )}
      {showColumn === "limit" && (
        <h4>
          <strong>List</strong> All Credit Accounts
        </h4>
      )}

      <div className='list-header'>
        <Button
          className='mui-button'
          variant='outlined'
          color='success'
          size='medium'
          onClick={() => navigate("/")}
        >
          Dashboard
        </Button>
      </div>
      {isLoading && (
        <div className='empty-list'>
          Getting credit accounts...
          <br />
          <br />
          <CircularProgress color='success' />
        </div>
      )}
      {!isLoading && creditAccounts?.length === 0 && (
        <div className='empty-list'>
          <h4>No credit accounts found</h4>
        </div>
      )}
      {creditAccounts?.length > 0 && (
        <table>
          <thead>
            <tr>
              <th className='list-item name'>Name</th>
              <th
                className={
                  showColumn === "limit" ? "list-item-right" : "list-item hide"
                }
              >
                Limit
              </th>
              <th
                className={
                  showColumn === "balance"
                    ? "list-item-right"
                    : "list-item hide"
                }
              >
                Balance
              </th>
              <th
                className={
                  showColumn === "used" ? "list-item-right" : "list-item hide"
                }
              >
                Utilization
              </th>
              <th className='list-item-right actions'>Actions</th>
            </tr>
          </thead>
          <tbody className='scrollable-content'>
            {creditAccounts.map((row) => (
              <tr key={row.accountId}>
                <td className='list-item name'>{row.name}</td>
                <td
                  className={
                    showColumn === "limit"
                      ? "list-item-right"
                      : "list-item hide"
                  }
                >
                  <NumberFormat
                    key='creditLimit'
                    name='creditLimit'
                    value={row.creditLimit}
                    prefix={"$"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                  />
                </td>
                <td
                  className={
                    showColumn === "balance"
                      ? "list-item-right"
                      : "list-item hide"
                  }
                >
                  <NumberFormat
                    key='currentBalance'
                    name='currentBalance'
                    value={row.currentBalance}
                    prefix={"$"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                  />
                </td>
                <td
                  className={
                    showColumn === "used" ? "list-item-right" : "list-item hide"
                  }
                >
                  <NumberFormat
                    key='creditUtilization'
                    name='creditUtilization'
                    value={row.creditUtilization}
                    suffix={"%"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                  />
                </td>
                <td className='list-item-right actions'>
                  <Button
                    variant='text'
                    onClick={() =>
                      navigate(`/accounts/${row.accountId}/transactions`)
                    }
                    color='inherit'
                  >
                    <MdTimeline />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <ActionAlert
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
      />
    </React.Fragment>
  );
}