import React, { useState, useEffect } from 'react';
import { MdTimeline, MdEdit, MdClear, MdContentCopy } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import './listBudgets.css';
import '../../../assets/css/list.css';
import { getBudgets, deleteBudget, copyBudget } from '../services/requests';
import { BudgetModel } from '../models/BudgetModel';
import { CopyBudgetModel } from '../models/CopyBudgetModel';
import CopyBudgetDialog from './CopyBudgetDialog';
import { CreateResponseModel } from '../../../shared/models/CreateResponseModel';
import ConfirmDeleteDialog from '../../../shared/components/ConfirmDeleteDialog/ConfirmDeleteDialog';
import { UpdateResponseModel } from '../../../shared/models/UpdateResponseModel';
import ActionAlert, { State } from '../../../shared/components/ActionAlert/ActionAlert';

export default function ListBudgets() {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [budgets, setBudgets] = useState<BudgetModel[]>([]);
  const [budget, setBudget] = useState<BudgetModel>(new BudgetModel());
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
  });
  const { open } = actionAlertState;
  const today = new Date();

  const handleResponse = (data: any, callback: Function)=> {
    if(typeof data === 'string') {
      setActionAlertState({ 
        open: true, 
        messages: [ data ], 
        severity: 'error'
      });
    } else {
      callback(data);
    }
  }

  const getBudgetList = async () => {
    const data = await getBudgets();
    await handleResponse(data, setBudgets);
    setIsLoading(false);
  }

  const copy = async (budget: CopyBudgetModel) => {
    const copyResult = (result: CreateResponseModel) => {
      if(result.errors.length > 0) {
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
        getBudgetList();
      }
      setIsSubmitting(false);
    }
    await copyBudget(budget, copyResult);
  }

  const deleteByBudgetId = async () => {
    const deleteResult = (result: UpdateResponseModel) => {
      if(result.errors.length > 0) {
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
        getBudgetList();
      }
      setIsSubmitting(false);
    }
    await deleteBudget(budget, deleteResult);
  }

  useEffect(() => {
    async function hasBudgets() {
      await getBudgetList();
      return budgets && budgets.length > 0;
    }
    hasBudgets();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenCopyDialog = (budget: BudgetModel) => {
    setBudget(budget);
    setOpenCopyDialog(true);
  };

  const handleOpenDeleteDialog = (budget: BudgetModel) => {
    if(budget) {
      setBudget(budget);
      setOpenDeleteDialog(true);
    }
  };

  const handleCancelCopy = () => {
    setOpenCopyDialog(false);
  };
 
  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleCopy = async (budget: CopyBudgetModel) => {
    handleCancelCopy();
    budget.monthId = Number(budget.monthId);
    budget.year = Number(budget.year);
    setIsSubmitting(true);
    await copy(budget);
  }

  const handleDelete = async () => {
    handleCancelDelete();
    if(budget) {
      setIsSubmitting(true);
      await deleteByBudgetId();
    }
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
  };

  return (
    <React.Fragment>
      <h4>
        <strong>List</strong> Budgets
      </h4>
      <div className='list-header'>
        <Button
          className='mui-button'
          variant='outlined'
          color='success'
          size='medium'
          onClick={() => navigate("/")}
        >
          Dashboard
        </Button>
        <Button
          className='mui-button'
          variant='outlined'
          size='medium'
          onClick={() => navigate("/budgets/create")}
        >
          New
        </Button>
      </div>
      <div className='linear-progress-container'>
        {isSubmitting && <LinearProgress color='success' />}
      </div>
      {isLoading && (
        <div className='empty-list'>
          Getting budgets...
          <br />
          <br />
          <CircularProgress color='success' />
        </div>
      )}
      {!isLoading && budgets?.length === 0 && (
        <div className='empty-list'>
          <h4>No budgets found</h4>
        </div>
      )}
      {!isLoading && budgets?.length > 0 && (
        <table>
          <thead>
            <tr>
              <th className='list-item'>Name</th>
              <th className='list-item-center hide'>Start</th>
              <th className='list-item-center hide'>End</th>
              <th className='list-item-right actions'>Actions</th>
            </tr>
          </thead>
          <tbody className='scrollable-content'>
            {budgets.map((row) => (
              <tr key={row.budgetId}>
                <td
                  className={
                    today >= new Date(row.startDate) &&
                    today <= new Date(row.endDate)
                      ? "current-budget"
                      : "list-item"
                  }
                >
                  {today >= new Date(row.startDate) &&
                  today <= new Date(row.endDate)
                    ? row.name + "  (current)"
                    : row.name}
                </td>
                <td
                  className={
                    today >= new Date(row.startDate) &&
                    today <= new Date(row.endDate)
                      ? "current-budget-center hide"
                      : "list-item-center hide"
                  }
                >
                  {row.startDate}
                </td>
                <td
                  className={
                    today >= new Date(row.startDate) &&
                    today <= new Date(row.endDate)
                      ? "current-budget-center hide"
                      : "list-item-center hide"
                  }
                >
                  {row.endDate}
                </td>
                <td
                  className={
                    today >= new Date(row.startDate) &&
                    today <= new Date(row.endDate)
                      ? "current-budget-right actions"
                      : "list-item-right actions"
                  }
                >
                  <Button
                    variant='text'
                    onClick={() => navigate(`/budgets/${row.budgetId}/manage`)}
                    color='inherit'
                  >
                    <MdTimeline />
                  </Button>
                  <Button
                    variant='text'
                    onClick={() => navigate(`/budgets/${row.budgetId}`)}
                    color='info'
                  >
                    <MdEdit />
                  </Button>
                  <Button
                    variant='text'
                    onClick={() => handleOpenCopyDialog(row)}
                    color='secondary'
                  >
                    <MdContentCopy />
                  </Button>
                  <Button
                    variant='text'
                    onClick={() => handleOpenDeleteDialog(row)}
                    color='error'
                  >
                    <MdClear />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <CopyBudgetDialog
        budgetId={budget.budgetId}
        budgetName={budget.name}
        openDialog={openCopyDialog}
        handleCancel={handleCancelCopy}
        handleCopy={handleCopy}
      />
      <ConfirmDeleteDialog
        title={"Confirm Delete Budget"}
        text={"Deleting this budget cannot be undone. Continue?"}
        openDialog={openDeleteDialog}
        handleCancel={handleCancelDelete}
        handleDelete={handleDelete}
      />
      <ActionAlert
        open={open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
      />
    </React.Fragment>
  );
}