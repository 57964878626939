import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaBars, FaUserAlt } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import './header.css';
import logo from '../../assets/images/logo.png';
import { clearSession } from '../../shared/services/localStorageService';
import { deleteSession } from '../../auth/services/requests';
import { UpdateResponseModel } from '../../shared/models/UpdateResponseModel';

const Header =({
  collapsed,
  handleToggleSidebar,
  handleCollapsedChange
}) => {

  const navigate = useNavigate();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(menuAnchor);

   const signOut = async () => {
    const signOutResult = (result: UpdateResponseModel) => {
      clearSession();
      navigate('/sign-in?loggedout=true');
    } 
    await deleteSession(signOutResult);
  }

  const handleActionsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  
  const handleActionMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleSignOut = () => {
    handleActionMenuClose();
    signOut();
  }

  return( 
    <div className='header-container'>
      <div className='mobile-view-container'>
        <div className="btn-toggle-mobile" onClick={() => handleToggleSidebar(true)}>
          <FaBars />
        </div>
        <div className='header-container-logo'>
          <a href='/'>
            <img src={logo} alt='' />
            <span>Ledgers</span>
          </a>
        </div>
        <div className='btn-profile-mobile'>
          <IconButton
            id='profile-button'
            aria-controls={openMenu ? 'actions-menu' : undefined}
            aria-expanded={openMenu ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleActionsMenu}
          >
            <FaUserAlt />
          </IconButton>
        </div>
      </div>
      <div className='desktop-view-container'>
        <div id='toggleSidebar' className='desktop-header-buttons'>
          <div className='btn-toggle-desktop' onClick={() => handleCollapsedChange(!collapsed)}>
            <FaBars />
          </div>
          <div className='btn-profile-desktop'>
            <IconButton
              id='profile-button'
              aria-controls={openMenu ? 'actions-menu' : undefined}
              aria-expanded={openMenu ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleActionsMenu}
            >
              <FaUserAlt />
            </IconButton>
          </div>
        </div>
      </div>
      <Menu
        id="actions-menu"
        anchorEl={menuAnchor}
        open={openMenu}
        onClose={handleActionMenuClose}
        MenuListProps={{
          'aria-labelledby': 'profile-button',
        }}
      >
        <div className='header-menu-container'>
          <MenuItem>
            <NavLink onClick={handleSignOut} to='' >
              Sign Out
            </NavLink> 
          </MenuItem>
        </div>
      </Menu>
    </div>
  );
}

export default Header;