import React, { memo, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import '../../../../assets/css/dialog.css';
import { getIncomeTypes } from '../../services/requests';
import { SelectListItemModel } from '../../../../shared/models/SelectListItemModel';
import { CreateIncomeModel } from '../../models/CreateIncomeModel';

const CreateIncomeDialog = ({
  openDialog,
  handleResponse,
  handleCancel,
  handleCreateIncome
}) => {

  const [incomeTypes, setIncomeTypes] = useState<SelectListItemModel[]>([]);
  const [income, setIncome] = useState<CreateIncomeModel>(new CreateIncomeModel());

  const getIncomeTypeSelectList = async () => {
    const data = await getIncomeTypes();
    await handleResponse(data, setIncomeTypes);
  }

  const handleUpdateValue = (name, value) => {
    setIncome(x => ({...x, [name]: value}));
  }

  const handleInputChange = (e) => {
    handleUpdateValue(e.target.name, e.target.value);
  }

  useEffect(() => {
    getIncomeTypeSelectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleCreateIncome(income);
  };

  return (
    <React.Fragment>
      <Dialog open={openDialog}>
        <DialogTitle>
        </DialogTitle>
        <DialogContent>
          Create New Income Source
        </DialogContent>
        <div className='dialog-form-container'>
          <form onSubmit={handleSubmit}>
            <div className='dialog-form-input-container'>
              <select 
                id='incomeTypeId' 
                name='incomeTypeId' 
                value={income.incomeTypeId} 
                onChange={handleInputChange}
                required
              >
              <option key='0' value={''} disabled={true}>Select Income Type...</option>
                {incomeTypes.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
              </select>
            </div>
            <div className='dialog-form-input-container'>
              <input 
                key='name' 
                name='name' 
                type='text' 
                placeholder='Income Name' 
                autoComplete='off' 
                onChange={handleInputChange} 
                required 
                minLength={3} 
                maxLength={50} 
              />
            </div>
            <DialogActions>
              <div className="dialog-action-container">
                <Button 
                  className='mui-button'
                  variant='outlined' 
                  color='error' 
                  size='medium'
                  onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  className='mui-button'
                  variant='outlined' 
                  color='success' 
                  size='medium'
                  type='submit'>
                  Submit
                </Button>
              </div>
            </DialogActions>
        </form>
      </div>
      </Dialog>
    </React.Fragment>
  );
}

export default memo(CreateIncomeDialog);