import { SidebarHeader } from 'react-pro-sidebar';

import './header.css';
import logo from '../../../assets/images/logo.png';

export default function Header() {

  return (
    <SidebarHeader>
      <div className='sidebar-header-container'>
        <a href='/'>
          <img src={logo} alt='' />
          <span>Ledgers</span>
        </a>
      </div>
    </SidebarHeader>
  )
}