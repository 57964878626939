import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import NumberFormat from 'react-number-format';
import CircularProgress from '@mui/material/CircularProgress';

import '../../../../assets/css/form.css';
import { getAccountSelectList } from '../../services/requests';
import { getIncomeSelectList } from '../../../Income/services/requests';
import { getMerchantSelectList } from '../../../Merchants/services/requests';
import { getTransaction, updatePayment } from '../services/requests';
import { SelectListItemModel } from '../../../../shared/models/SelectListItemModel';
import { UpdateAccountPaymentModel } from '../models/UpdateAccountPaymentModel';
import { UpdateResponseModel } from '../../../../shared/models/UpdateResponseModel';
import { PrimaryAccountBalanceModel } from '../../../Dashboard/models/PrimaryAccountBalanceModel';
import ActionAlert, { State } from '../../../../shared/components/ActionAlert/ActionAlert';

export default function UpdateAccountPayment() {

  const { accountId } = useParams();
  const { transactionId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [payment, setPayment] = useState<UpdateAccountPaymentModel>({});
  const [sources, setSources] = useState<SelectListItemModel[]>([]);
  const [destinations, setDestinations] = useState<SelectListItemModel[]>([]);
  const [sourceDefault, setSourceDefault] = useState<string>('--')
  const [destinationDefault, setDesinationDefault] = useState<string>('--')
  const [hasError, setHasError] = useState<boolean>(false);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
  });

  const handleResponse = (data: any, callback: Function): boolean => {
    let isSuccess: boolean = false;
    if(typeof data === 'string') {
      setActionAlertState({ 
        open: true, 
        messages: [ data ], 
        severity: 'error'
      });
    } else {
      callback(data);
      isSuccess = true;
    }
    return isSuccess;
  }

  const getAccountList = async (callback: Function) => {
    const data = await getAccountSelectList();
    await handleResponse(data, callback)
  }

  const setIncomeList = async (callback: Function) => {
    const data = await getIncomeSelectList();
    await handleResponse(data, callback)
  }

  const getMerchantList = async (callback: Function) => {
    const data = await getMerchantSelectList();
    await handleResponse(data, callback)
  }

  const setSourceInstitutions = async (institutionTypeId: number) => {
    switch (institutionTypeId) {
      case 1:
        setSourceDefault('Select Account...');
        await getAccountList(setSources);
        break;
      case 2:
        setSourceDefault('Select Merchant...');
        await getMerchantList(setSources);
        break;
      case 3:
        setSourceDefault('Select Income Source...');
        await setIncomeList(setSources);
        break;
    }
  }

  const setDestinationInstitutions = async (institutionTypeId: number) => {
    switch (institutionTypeId) {
      case 1:
        setDesinationDefault('Select Account...');
        await getAccountList(setDestinations);
        break;
      case 2:
        setDesinationDefault('Select Merchant...');
        await getMerchantList(setDestinations);
        break;
      case 3:
        setDesinationDefault('Select Income Source...');
        await setIncomeList(setDestinations);
        break;
    }
  }

  const getTransactionById = async () => {
    const data = await getTransaction(accountId, transactionId);
    const isSuccess = await handleResponse(data, setPayment);
    if(isSuccess) {
      await setSourceInstitutions(data.sourceInstitutionTypeId);
      await setDestinationInstitutions(data.destinationInstitutionTypeId);
    }
  }

  const update = async () => {
    const updateResult = (result: UpdateResponseModel) => {
      if(result.errors.length > 0) {
        setHasError(true);
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setHasError(false);
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
      }
      setIsSubmitting(false);
    }
    await updatePayment(payment, updateResult);
  }

  const handleUpdateValue = (name, value) => {
    if(name === 'amount') {
      value = value.replace(',', '');
    }
    setPayment(x => ({...x, [name]: value}));
  }

  const handleInputChange = (e) => {
    handleUpdateValue(e.target.name, e.target.value);
  }

  useEffect(() => {
    getTransactionById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await update();
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
    if(!hasError) {
      navigate(`/accounts/${accountId}/transactions`);
    }
  };

  return (
    <React.Fragment>
      <h4><strong>Update</strong> Account Payment</h4>
      <div className='form-container'>
        <form onSubmit={handleSubmit}>
        <div className='form-input-container'>
          <select 
            id='sourceInstitutionId' 
            name='sourceInstitutionId' 
            value={payment.sourceInstitutionId} 
            onChange={handleInputChange}
            required
          >
            <option key='0' value={''} disabled={true}>{sourceDefault}</option>
            {sources.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
          </select>
          </div>
          <div className='form-input-container'>
            <select 
              id='destinationInstitutionId' 
              name='destinationInstitutionId' 
              value={payment.destinationInstitutionId} 
              onChange={handleInputChange}
              required
            >
              <option key='0' value={''} disabled={true}>{destinationDefault}</option>
              {destinations.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
            </select>
          </div>
          <div className='form-input-container'>
            <NumberFormat
              key='amount'
              name='amount'
              value={payment.amount}
              className='value'
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={'input'}
              onChange={handleInputChange} 
              required={true}
              min={0}
            />
          </div>
          <div className='form-input-container'>
            <input 
              key='date'
              name='date' 
              type='date' 
              placeholder='Date' 
              autoComplete='off' 
              defaultValue={payment.date} 
              onChange={handleInputChange} 
            />
          </div>
          <div className='form-input-container'>
            <textarea 
              key='notes' 
              name='notes' 
              placeholder='Notes' 
              autoComplete='off' 
              defaultValue={payment.notes} 
              onChange={handleInputChange} 
              maxLength={1000} 
            />
          </div>
          <div className='form-buttons-container'>
            <Button 
              className='mui-button' 
              variant='outlined' 
              color='error' 
              size='medium' 
              onClick={() => navigate(`/accounts/${accountId}/transactions`)}>
              Cancel
            </Button>
            <Button 
              className='mui-button' 
              variant='outlined' 
              color='success' 
              size='medium' 
              type='submit'>
              {!isSubmitting && (
                <span>Submit</span>
              )}
              {isSubmitting && (
                <CircularProgress 
                  color='success' 
                  size={24}
                />
              )}
            </Button>
          </div>
        </form>
      </div>
      <ActionAlert 
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
      />
    </React.Fragment>
  );
}