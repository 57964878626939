import configuration from '../../../api-config.json';
import { GET } from '../../../shared/services/apiRequests';
import { PrimaryAccountBalanceModel } from '../models/PrimaryAccountBalanceModel';
import { CreditTotalsModel } from '../models/CreditTotalsModel';
import { PrimaryAccountForecastModel } from '../models/PrimaryAccountForecastModel';

const baseUrl = `${configuration.API_URL}/dashboard`;
const routeVersion1 = '/v1';

export const getPrimaryAccountBalance = async (): Promise<PrimaryAccountBalanceModel | string> => {
  const url = baseUrl + routeVersion1 + '/primary-account-balance';
  return await GET<PrimaryAccountBalanceModel>(url, true);
};

export const getCreditTotals = async (): Promise<CreditTotalsModel | string> => {
  const url = baseUrl + routeVersion1 + '/credit-totals';
  return await GET<CreditTotalsModel>(url, true);
};

export const getPrimaryAccountForecast = async (isMobile: boolean): Promise<PrimaryAccountForecastModel | string> => {
  const url = baseUrl + routeVersion1 + '/primary-account-forecast?isMobile=' + isMobile;
  return await GET<PrimaryAccountForecastModel>(url, true);
};