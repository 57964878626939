export class CopyBudgetModel {
  budgetId!: number;
  monthId: number | string | undefined = '';
  year: number | string | undefined = '';
  name?: string | null = null;
}

export class MonthModel {
  monthId!: number;
  month!: string;
}

export class YearModel {
  yearId!: number;
  year!: number;
}
