import React from 'react';

import './notFound.css';
import notFound from '../../assets/images/404.jpg';

export default function NotFound() {

  return (
    <React.Fragment>
      <div className='not-found-container'>
        <h1>Page Not Found</h1>
      </div>
    </React.Fragment>
  );
}