import React, { useState, useEffect } from 'react';
import { MdEdit, MdClear } from 'react-icons/md';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import NumberFormat from 'react-number-format';
import CircularProgress from '@mui/material/CircularProgress';

import '../../../../assets/css/list.css';
import { getTransactions, deleteTransaction } from '../services/requests';
import { IncomeTransactionsModel } from '../models/IncomeTransactionsModel';
import { LedgerTransactionModel } from '../../../../shared/models/LedgerTransactionModel';
import ConfirmDeleteDialog from '../../../../shared/components/ConfirmDeleteDialog/ConfirmDeleteDialog';
import { UpdateResponseModel } from '../../../../shared/models/UpdateResponseModel';
import ActionAlert, { State } from '../../../../shared/components/ActionAlert/ActionAlert';

export default function ListIncomeTransactions() {

  const { incomeId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transactions, setTransactions] = useState<IncomeTransactionsModel>(new IncomeTransactionsModel());
  const [transaction, setTransaction] = useState<LedgerTransactionModel>(new LedgerTransactionModel());
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
  });
  const payIncomePath = `/income/${incomeId}/transactions/pay`

  const getTransactionList = async () => {
    const data = await getTransactions(incomeId);
    setTransactions(data);
    setIsLoading(false);
  }

  const deleteByTransactionId = async () => {
    const deleteResult = (result: UpdateResponseModel) => {
      if(result.errors.length > 0) {
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
        getTransactionList();
      }
      setIsSubmitting(false);
    }
    await deleteTransaction(incomeId, transaction, deleteResult);
  }

  useEffect(() => {
    async function hasTransactions() {
      await getTransactionList();
      return transactions && transactions.transactions.length > 0;
    }
    hasTransactions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenDeleteDialog = (transaction: LedgerTransactionModel) => {
    if(transaction) {
      setTransaction(transaction);
      setOpenDeleteDialog(true);
    }
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = async () => {
    handleCancelDelete();
    if(transaction) {
      setIsSubmitting(true);
      await deleteByTransactionId();
    }
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
  };

  return (
    <React.Fragment>
      <h4>
        <strong>List</strong> Transactions for{" "}
        <strong>{transactions.accountName}</strong>
      </h4>
      <div className='list-header'>
        <Button
          className='mui-button'
          variant='outlined'
          color='success'
          size='medium'
          onClick={() => navigate("/income")}
        >
          Income
        </Button>
        <Button
          className='mui-button'
          variant='outlined'
          size='medium'
          onClick={() => navigate(payIncomePath)}
        >
          New
        </Button>
      </div>
      <div className='linear-progress-container'>
        {isSubmitting && <LinearProgress color='success' />}
      </div>
      <div className='totals-section'>
        <div className='totals-container'>
          <div className='totals-title'>Total Received:</div>
          <div className='totals-amount'>
            <NumberFormat
              key='currentBalance'
              name='currentBalance'
              value={transactions.currentBalance}
              prefix={"$"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"text"}
            />
          </div>
        </div>
      </div>
      {isLoading && (
        <div className='empty-list'>
          Getting transactions...
          <br />
          <br />
          <CircularProgress color='success' />
        </div>
      )}
      {!isLoading && transactions?.transactions?.length === 0 && (
        <div className='empty-list'>
          <h4>No transactions found</h4>
        </div>
      )}
      {!isLoading && transactions?.transactions?.length > 0 && (
        <table>
          <thead>
            <tr>
              <th className='list-item hide'>From</th>
              <th className='list-item name'>To</th>
              <th className='list-item-right'>Amount</th>
              <th className='list-item-center hide'>Date</th>
              <th className='list-item-right actions'>Actions</th>
            </tr>
          </thead>
          <tbody className='scrollable-content-transactions'>
            {transactions.transactions.map((row) => (
              <tr key={row.ledgerTransactionId}>
                <td className='list-item hide'>{row.sourceInstitutionName}</td>
                <td className='list-item name'>
                  {row.destinationInstitutionName}
                </td>
                <td className='list-item-right'>
                  <NumberFormat
                    value={row.amount}
                    className={
                      row.ledgerEntryTypeId !== 1
                        ? "negative-number"
                        : "positive-number"
                    }
                    prefix={"$"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                  />
                </td>
                <td className='list-item-center hide'>{row.date}</td>
                <td className='list-item-right actions'>
                  <Button
                    variant='text'
                    onClick={() =>
                      navigate(
                        `/income/${incomeId}/transactions/${row.ledgerTransactionId}`
                      )
                    }
                    color='info'
                  >
                    <MdEdit />
                  </Button>
                  <Button
                    variant='text'
                    onClick={() => handleOpenDeleteDialog(row)}
                    color='error'
                  >
                    <MdClear />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <ConfirmDeleteDialog
        title={"Confirm Delete Transaction"}
        text={"Deleting this transaction cannot be undone. Continue?"}
        openDialog={openDeleteDialog}
        handleCancel={handleCancelDelete}
        handleDelete={handleDelete}
      />
      <ActionAlert
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
      />
    </React.Fragment>
  );
}
