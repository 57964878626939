import { ProSidebar } from 'react-pro-sidebar';

import './sidebar.css';
import Nav from './Nav/Nav';
import User from './User/User';
import Header from './Header/Header';

const Sidebar = ({ 
  currentUser,
  setCurrentUser,
  collapsed, 
  toggled, 
  handleToggleSidebar 
}) => {

  return (
    <div className='sidebar-container'>
      <ProSidebar
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
      >
        <Header />
        <User 
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
        />
        <Nav 
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
        />
      </ProSidebar>
    </div>
  );
};

export default Sidebar;
