import configuration from '../../api-config.json';
import { POST, PUT} from '../../shared/services/apiRequests';
import { getToken } from '../../shared/services/localStorageService';
import { setToken, setUser } from '../../shared/services/localStorageService';
import { SignUpModel } from '../models/SignUpModel';
import { SignInModel } from '../models/SignInModel';
import { EmailModel } from '../models/EmailModel';
import { CreateResponseModel } from '../../shared/models/CreateResponseModel';
import { UpdateResponseModel } from '../../shared/models/UpdateResponseModel';
import { ValidateTokenModel } from '../models/ValidateTokenModel';
import { ResetPasswordModel } from '../models/ResetPasswordModel';

const baseUrl = `${configuration.API_URL}/sessions`;
const routeVersion1 = '/v1';

export const createUser = async (data: SignUpModel, handleResult: Function) :
Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/sign-up';
  const successMessage = 'Your account was successfully created!';
  data.publicKey = configuration.PUBLIC_KEY;
  await POST<SignUpModel>(data, url, successMessage, false, handleResult);
}

export const createSession = async (data: SignInModel, handleResult: Function) :
Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1;
  const headers = new Headers({ 
    'Content-Type': 'application/json',
  });
  data.publicKey = configuration.PUBLIC_KEY;
  const result = new CreateResponseModel();
  await fetch(url + '/sign-in', {
    method: 'POST',
    body: JSON.stringify(data),
    headers 
  })
  .then(response => 
    response.json()
      .then(data => ({
        data: data,
        status: response.status
      })
  )
  .then(res => {
    if(response.status === 400) {
      result.errors.push('There was an unknown problem with your login');
      handleResult(result);
    }
    if(res.status === 200) {
      setToken(res.data.token);
      setUser(res.data.user);
      result.success = `Welcome back ${res.data.user.firstName}!`;
    }
    if(res.status === 401) {
      result.errors.push(res.data.errorMessage);
    }
    handleResult(result);
  })
  .catch(error => {
    if (typeof error.json === "function") {
      error.json().then(jsonError => {
        result.errors.push(...jsonError);
        handleResult(result);
      }).catch(genericError => {
        result.errors.push(genericError);
        handleResult(result);
      });
    }
  }));
}

export const deleteSession = async (deleteResult: Function) :
Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1;
  const token = getToken();
  const headers = new Headers({ 
    'Content-Type': 'application/json',
  });
  const result = new UpdateResponseModel();
  if(token) {
    headers.append('Authorization', `Bearer ${token}`);
    await fetch(url, {
      method: 'DELETE',
      headers 
    })
    .then(response => {
    })
    .catch(error => {
    });
  }
  deleteResult(result);
}

export const createResetPasswordToken = async (data: EmailModel, handleResult: Function) :
Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/create-reset-password-token';
  const successMessage = 'Check your email to continue';
  data.publicKey = configuration.PUBLIC_KEY;
  await POST<EmailModel>(data, url, successMessage, false, handleResult);
}

export const validateResetPasswordToken = async (data: ValidateTokenModel, handleResult: Function) :
Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/validate-reset-password-token';
  const successMessage = 'Reset Password Link Successfully Validated!';
  data.publicKey = configuration.PUBLIC_KEY;
  await POST<ValidateTokenModel>(data, url, successMessage, false, handleResult);
}

export const resetPassword = async (data: ResetPasswordModel, handleResult: Function) :
Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/reset-password';
  const successMessage = 'Your password was successfully changed!';
  data.publicKey = configuration.PUBLIC_KEY;
  await PUT<ResetPasswordModel>(data, url, successMessage, false, handleResult);
}
