import { Navigate } from 'react-router-dom';

import { getToken, getUser } from '../../services/localStorageService';

const AdminProtectedRoute = ({
  children
}) => {

  const isAdminAuthenticated = () => {
    const user = getUser();
    const token = getToken();

    if(token && user) {
      const isAdmin: boolean = user.isAdmin;
      const parsedToken = JSON.parse(window.atob(token.split(".")[1]));
      if(isAdmin && parsedToken.exp * 1000 > Date.now()) {
        return true;
      }
    }
    return false;
  }

  if(!isAdminAuthenticated()) {
    return  (
      <Navigate to='/' replace/>
    );
  }
  return children;
}

export default AdminProtectedRoute;
