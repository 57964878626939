import React, { useEffect, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import '../../assets/css/form.css';
import '../../assets/css/auth.css';
import logo from '../../assets/images/logo-large.png';
import { createUser } from '../services/requests';
import { validateInviteRequestToken } from '../../features/InviteRequests/services/requests';
import { ValidateTokenModel } from '../models/ValidateTokenModel';
import { SignUpModel } from '../models/SignUpModel';
import { CreateResponseModel } from '../../shared/models/CreateResponseModel';
import ActionAlert, { State } from '../../shared/components/ActionAlert/ActionAlert';

export default function SignUp() {

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [shouldNavigate, setShouldNavigate] = useState<boolean>(true);
  const [registration, setRegistration] = useState<SignUpModel>(new SignUpModel());
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
    autoHideDuration: 0
  });

  const validate = async (tokenValidation: ValidateTokenModel) => {
    const createResult = (result: CreateResponseModel) => {
      if(result.errors.length > 0) {
        setHasError(true);
        setShouldNavigate(true);
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setHasError(false);
        setShouldNavigate(false);
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
      }
      setIsSubmitting(false);
    }
    await validateInviteRequestToken(tokenValidation, createResult);
  }

  const signUp = async () => {
    const createResult = (result: CreateResponseModel) => {
      if(result.errors.length > 0) {
        setHasError(true);
        setShouldNavigate(false);
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setHasError(false);
        setShouldNavigate(true);
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
      }
      setIsSubmitting(false);
    }
    await createUser(registration, createResult);
  }

  const handleUpdateValue = (name, value) => {
    setRegistration(x => ({...x, [name]: value}));
  }

  const handleInputChange = (e) => {
    handleUpdateValue(e.target.name, e.target.value);
  }

  useEffect(() => {
    if(token === undefined || token === null) {
      setHasError(true);
      setShouldNavigate(true);
      setActionAlertState({ 
        open: true, 
        messages: [ 'Your token is invalid or expired' ], 
        severity: 'error',
        autoHideDuration: 4000
      });
    } else {
      handleUpdateValue('token', token);
      let tokenValidation = new ValidateTokenModel();
      tokenValidation.token = token;
      validate(tokenValidation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await signUp();
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
    if(!hasError) {
      if(shouldNavigate) {
        navigate('/sign-in');
      }
    } else {
      if(shouldNavigate) {
        navigate('/request-invite');
      }
    }
  };

  return (
    <React.Fragment>
      <div className='auth-container'>
        <div className='auth-action-container'>
          <div className='auth-action-section'>
            <div className='auth-action-logo'>
              <img src={logo} alt='' />
              <span>Ledgers</span>
            </div>
            <div className='auth-action-title'>
              <span>Register New User</span>
            </div>
            <div className='auth-action-subtitle'>
              <span></span>
            </div>
            <div className='form-container'>
              <form onSubmit={handleSubmit}>
                <div className='form-input-container'>
                  <input 
                    key='username' 
                    name='username' 
                    type='text' 
                    placeholder='Username' 
                    autoComplete='off' 
                    autoCapitalize='off'
                    onChange={handleInputChange} 
                    required 
                  />
                </div>
                <div className='form-input-container'>
                  <input 
                    key='password' 
                    name='password' 
                    type='password' 
                    placeholder='Password' 
                    autoComplete='off' 
                    autoCapitalize='off'
                    onChange={handleInputChange} 
                    required 
                  />
                </div>
                <div className='form-input-container'>
                  <input 
                    key='confirmPassword' 
                    name='confirmPassword' 
                    type='password' 
                    placeholder='Confirm Password' 
                    autoComplete='off' 
                    autoCapitalize='off'
                    onChange={handleInputChange} 
                    required 
                  />
                </div>
                <div className='form-buttons-container'>
                  <Button 
                    className='auth-action-button' 
                    variant='outlined' 
                    color='success' 
                    size='medium' 
                    type='submit'>
                    {!isSubmitting && (
                      <span>Sign Up</span>
                    )}
                    {isSubmitting && (
                      <CircularProgress 
                        color='success' 
                        size={24}
                      />
                    )}
                  </Button>
                </div>
              </form>
            </div>
            <div className='sign-in'>
              Already signed up?
              <NavLink to='/sign-in' >
                &nbsp;Sign In
              </NavLink> 
            </div>
          </div>
        </div>
        <div className='auth-background-container'>
        </div>
      </div>
      <ActionAlert 
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
        autoHideDuration={actionAlertState.autoHideDuration}
      />
    </React.Fragment>
  )
}
