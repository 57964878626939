import configuration from '../../../api-config.json';
import { POST, GET, GETLIST, PUT, DELETE } from '../../../shared/services/apiRequests';
import { BudgetModel } from '../models/BudgetModel';
import { ManageBudgetModel } from '../models/ManageBudgetModel';
import { CreateResponseModel } from '../../../shared/models/CreateResponseModel';
import { CreateBudgetModel } from '../models/CreateBudgetModel';
import { UpdateBudgetModel } from '../models/UpdateBudgetModel';
import { UpdateResponseModel } from '../../../shared/models/UpdateResponseModel';
import { CopyBudgetModel } from '../models/CopyBudgetModel';
import { SelectListItemModel } from '../../../shared/models/SelectListItemModel';
import { CreateBudgetItemModel } from '../models/CreateBudgetItemModel';
import { ManageBudgetItemModel } from '../models/ManageBudgetItemModel';

const entity = 'budgets';
const baseUrl = `${configuration.API_URL}/${entity}`;
const routeVersion1 = '/v1';

export const createBudget = async (data: CreateBudgetModel, handleResult: Function): Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1;
  const successMessage = `${data?.name} was created successfully!`;
  await POST<CreateBudgetModel>(data, url, successMessage, true, handleResult);
}

export const copyBudget = async (data: CopyBudgetModel, handleResult: Function): Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/copy';
  const successMessage = `${data?.name} was created successfully!`;
  await POST<CopyBudgetModel>(data, url, successMessage, true, handleResult);
}

export const getBudget = async (id: string | undefined): Promise<UpdateBudgetModel | string> => {
  const url = baseUrl + routeVersion1+ '/' +  id;
  return await GET<UpdateBudgetModel>(url, true);
}

export const getBudgets = async (): Promise<Array<BudgetModel> | string> => {
  const url = baseUrl + routeVersion1 + '/list';
  return await GETLIST<Array<BudgetModel>>(url, true);
}

export const getManageBudget = async (id: string | undefined): Promise<ManageBudgetModel | string> => {
  const url = baseUrl + routeVersion1 + '/' +  id + '/manage';
  return await GET<ManageBudgetModel>(url, true);
}

export const getBudgetItem = async (id: string | undefined, itemId: string | undefined): Promise<ManageBudgetItemModel | string> => {
  const url = baseUrl + routeVersion1+ '/' +  id + '/budget-items/' + itemId;
  return await GET<ManageBudgetItemModel>(url, true);
}

export const updateBudget = async (data: UpdateBudgetModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1;
  const successMessage = `${data?.name} was updated successfully!`;
  await PUT<UpdateBudgetModel>(data, url, successMessage, true, handleResult);
}

export const deleteBudget = async (data: BudgetModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/' +  data.budgetId;
  const successMessage = `${data.name} was deleted successfully!`;
  await DELETE(url, successMessage, true, handleResult);
}

export const getBudgetCategoryTypes = async (): Promise<Array<SelectListItemModel> | string> => {
  const url = baseUrl + routeVersion1 + '/category-types';
  return await GETLIST<Array<SelectListItemModel>>(url, true);
}

export const createBudgetEntry = async (data: CreateBudgetItemModel, handleResult: Function): Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/budget-items';
  const successMessage = 'Budget item was created successfully!';
  await POST<CreateBudgetItemModel>(data, url, successMessage, true, handleResult);
}

export const deleteBudgetEntry = async (data: ManageBudgetItemModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/' +  data.budgetId + '/budget-items/' + data.budgetItemId;
  const successMessage = 'Budget item was deleted successfully!';
  await DELETE(url, successMessage, true, handleResult);
}
