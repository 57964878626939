import React, { useState, useEffect } from 'react';
import { MdDone, MdClear } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import '../../../assets/css/list.css';
import { getInviteRequests, approveInviteRequest, denyInviteRequest } from '../services/requests';
import { InviteRequestModel } from '../models/InviteRequestModel';
import { UpdateResponseModel } from '../../../shared/models/UpdateResponseModel';
import ConfirmActionDialog from '../../../shared/components/ConfirmActionDialog/ConfirmActionDialog';
import ActionAlert, { State } from '../../../shared/components/ActionAlert/ActionAlert';

export default function ManageInvites() {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inviteRequests, setInviteRequests] = useState<InviteRequestModel[]>([]);
  const [inviteRequest, setInviteRequest] = useState<InviteRequestModel>(new InviteRequestModel());
  const [openDenyDialog, setOpenDenyDialog] = useState(false);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [actionAlertState, setActionAlertState] = useState<State>({
    open: false,
    messages: [],
    severity: '',
  });

  const handleResponse = (data: any, callback: Function)=> {
    if(typeof data === 'string') {
      setActionAlertState({ 
        open: true, 
        messages: [ data ], 
        severity: 'error'
      });
    } else {
      callback(data);
    }
  }

  const getInviteRequestList = async () => {
    const data = await getInviteRequests();
    await handleResponse(data, setInviteRequests);
    setIsLoading(false);
  }

  const deny = async () => {
    const updateResult = (result: UpdateResponseModel) => {
      if(result.errors.length > 0) {
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
        getInviteRequestList();
      }
      setIsSubmitting(false);
    }
    await denyInviteRequest(inviteRequest, updateResult);
  }

  const approve = async () => {
    const createResult = (result: UpdateResponseModel) => {
      if(result.errors.length > 0) {
        setActionAlertState({ 
          open: true, 
          messages: result.errors, 
          severity: 'error'
        });
      } else {
        setActionAlertState({ 
          open: true, 
          messages: [ result.success ], 
          severity: 'success'
        });
        getInviteRequestList();
      }
      setIsSubmitting(false);
    }
    await approveInviteRequest(inviteRequest, createResult);
  }

  useEffect(() => {
    async function hasInvites() {
      await getInviteRequestList();
      return inviteRequests && inviteRequests.length > 0;
    }
    hasInvites();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenDenyDialog = (inviteRequest: InviteRequestModel) => {
    if(inviteRequest) {
      setInviteRequest(inviteRequest);
      setOpenDenyDialog(true);
    }
  };

  const handleCancelDeny = () => {
    setOpenDenyDialog(false);
  };

  const handleDeny = async () => {
    handleCancelDeny();
    if(inviteRequest) {
      setIsSubmitting(true);
      await deny();
    }
  };

  const handleOpenApproveDialog = (inviteRequest: InviteRequestModel) => {
    if(inviteRequest) {
      setInviteRequest(inviteRequest);
      setOpenApproveDialog(true);
    }
  };

  const handleCancelApprove = () => {
    setOpenApproveDialog(false);
  };

  const handleApprove = async () => {
    handleCancelApprove();
    if(inviteRequest) {
      setIsSubmitting(true);
      await approve();
    }
  };

  const handleActionAlertClose = () => {
    setActionAlertState({ ...actionAlertState, open: false });
  };

  return (
    <React.Fragment>
      <h4>
        <strong>Manage</strong> Invite Requests
      </h4>
      <div className='list-header'>
        <Button
          className='mui-button'
          variant='outlined'
          color='success'
          size='medium'
          onClick={() => navigate("/")}
        >
          Dashboard
        </Button>
      </div>
      <div className='linear-progress-container'>
        {isSubmitting && <LinearProgress color='success' />}
      </div>
      {isLoading && (
        <div className='empty-list'>
          Getting invite requests...
          <br />
          <br />
          <CircularProgress color='success' />
        </div>
      )}
      {!isLoading && inviteRequests?.length === 0 && (
        <div className='empty-list'>
          <h4>No invite requests found</h4>
        </div>
      )}
      {inviteRequests?.length > 0 && (
        <table>
          <thead>
            <tr>
              <th className='list-item name'>Name</th>
              <th className='list-item hide'>Email</th>
              <th className='list-item'>Requested</th>
              <th className='list-item-right actions'>Actions</th>
            </tr>
          </thead>
          <tbody className='scrollable-content'>
            {inviteRequests.map((row) => (
              <tr key={row.inviteRequestId}>
                <td className='list-item name'>
                  {row.firstName + " " + row.lastName}
                </td>
                <td className='list-item hide'>{row.email}</td>
                <td className='list-item'>{row.requestDate}</td>
                <td className='list-item-right actions'>
                  <Button
                    variant='text'
                    onClick={() => handleOpenApproveDialog(row)}
                    color='success'
                  >
                    <MdDone />
                  </Button>
                  <Button
                    variant='text'
                    onClick={() => handleOpenDenyDialog(row)}
                    color='error'
                  >
                    <MdClear />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <ConfirmActionDialog
        title={"Confirm Deny Invite Request"}
        text={"Are you sure you want to deny this invite request?"}
        openDialog={openDenyDialog}
        handleCancel={handleCancelDeny}
        handleAction={handleDeny}
        actionName={"Deny"}
      />
      <ConfirmActionDialog
        title={"Confirm Enable User"}
        text={"Are you sure you want to enable this user?"}
        openDialog={openApproveDialog}
        handleCancel={handleCancelApprove}
        handleAction={handleApprove}
        actionName={"Approve"}
      />
      <ActionAlert
        open={actionAlertState.open}
        messages={actionAlertState.messages}
        severity={actionAlertState.severity}
        handleClose={handleActionAlertClose}
      />
    </React.Fragment>
  );
}