import { CurrentUserModel } from '../models/CurrentUserModel';

export function getToken(): string {
  const data = localStorage.getItem('token');
  return data !== null ? JSON.parse(data) : null;
}

export function setToken(data: string) {
  localStorage.setItem('token', JSON.stringify(data));
}

export function getUser(): CurrentUserModel {
  const data = localStorage.getItem('user');
  return data !== null ? JSON.parse(data) : null;
}

export function setUser(data: CurrentUserModel) {
  localStorage.setItem('user', JSON.stringify(data));
}

export function clearSession() {
  localStorage.clear();
  sessionStorage.clear();
}
