import configuration from '../../../../api-config.json';
import { POST, GET, PUT, DELETE } from '../../../../shared/services/apiRequests';
import { PayMerchantModel } from '../models/PayMerchantModel';
import { CreateResponseModel } from '../../../../shared/models/CreateResponseModel';
import { UpdateMerchantPaymentModel } from '../models/UpdateMerchantPaymentModel';
import { UpdateResponseModel } from '../../../../shared/models/UpdateResponseModel';
import { MerchantTransactionsModel } from '../models/MerchantTransactionsModel';
import { LedgerTransactionModel } from '../../../../shared/models/LedgerTransactionModel';

const baseUrl = `${configuration.API_URL}/merchants`;
const routeVersion1 = '/v1';

export const payMerchant = async (data: PayMerchantModel, handleResult: Function): Promise<CreateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/transactions/pay';
  const successMessage = 'Payment saved successfully!';
  await POST<PayMerchantModel>(data, url, successMessage, true, handleResult);
}

export const getTransaction = async (id: string | undefined, transactionId: string | undefined): Promise<UpdateMerchantPaymentModel | any> =>  {
  const url = baseUrl + routeVersion1 + '/' + id + '/transactions/' + transactionId;
  return await GET<UpdateMerchantPaymentModel>(url, true);
}

export const getTransactions = async (id: string | undefined): Promise<MerchantTransactionsModel | any> =>  {
  const url = baseUrl + routeVersion1 + '/' + id + '/transactions';
  return await GET<MerchantTransactionsModel>(url, true);
}

export const updatePayment = async (data: UpdateMerchantPaymentModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/' +  data?.sourceInstitutionId + '/transactions/' + data?.ledgerTransactionId;
  const successMessage = 'Payment updated successfully!';
  await PUT<UpdateMerchantPaymentModel>(data, url, successMessage, true, handleResult);
}

export const deleteTransaction = async (id: string | undefined, data: LedgerTransactionModel, handleResult: Function): Promise<UpdateResponseModel | any> => {
  const url = baseUrl + routeVersion1 + '/' + id + '/transactions/' + data.ledgerTransactionId;
  const successMessage = 'Transaction was deleted successfully!';
  await DELETE(url, successMessage, true, handleResult);
}
