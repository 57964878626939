import { Outlet } from 'react-router-dom';

import './main.css';

export default function Main() {

  return (
    <div id='outlet' className='outlet-container'>
      <div className='page-container'>
        <Outlet />
      </div>
    </div>
  );
};
