import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import ActionAlertMessage from './ActionAlertMessage';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export interface State {
  open: boolean;
  messages: string[],
  severity: string;
  autoHideDuration?: number
}

const ActionAlert = ({
  open,
  messages,
  severity,
  handleClose,
  autoHideDuration = 3000
}) => {

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
        autoHideDuration={autoHideDuration}
        open={open}
        onClose={handleClose}
        key={'topcenter'}
      >
        <Alert severity={severity} sx={{ width: '100%' }}>
          <ActionAlertMessage
            messages={messages}
          />
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ActionAlert; 