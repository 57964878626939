
export class CurrentMonthForecast {
  budgetId: number = 0;
  budgetedIncomeReceived: number = 0;
  unplannedIncomeReceived: number = 0;
  budgetExpensesPaid: number = 0;
  unplannedExpensesPaid: number = 0;
  currentBalance: number = 0;
  upcomingIncome: number = 0;
  upcomingExpenses: number = 0;
  currentMonthBalance: number = 0;
}
