
export class ManageBudgetItemModel {
  accountId: number = 0;
  budgetItemId: number = 0;
  budgetId: number = 0;
  budgetCategoryTypeId: number = 0;
  institutionId: number = 0;
  institutionName: string = '';
  institutionTypeId: number = 0;
  url: string = '';
  actualAmount: number = 0;
  amount: number = 0;
  dueDate?: string = '';
  dueDateReverseFormat?: string = '';
}
