import './mobile.css';
import ClickableWidget from '../Widget/ClickableWidget';

const Mobile = ({
  primaryAccountBalance,
  creditTotals,
  handleGoToPrimaryAccount,
  handleGoToCreditAccounts
}) => {

  return (
    <div className='mobile-container'>
      <div className='mobile-widget-container'>
        <ClickableWidget
          title={primaryAccountBalance.accountName}
          amount={primaryAccountBalance.balance}
          borderColor={'#2596be'}
          backgroundColor={'#e9f5f9'}
          handleOnClick={() => handleGoToPrimaryAccount(primaryAccountBalance.accountId)}
          showAsNegative={primaryAccountBalance.balance < 0 ? true : false}
        />
      </div>
      <div className='mobile-widget-container'>
        <ClickableWidget
          title={'Total Credit'}
          amount={creditTotals.totalCredit}
          borderColor={'#49be25'}
          backgroundColor={'#dbf2d3'}
          handleOnClick={() => handleGoToCreditAccounts('limit')}
        />
      </div>
      <div className='mobile-widget-container'>
        <ClickableWidget
          title={'Credit Balance'}
          amount={creditTotals.totalCreditBalance}
          borderColor={'#be4d25'}
          backgroundColor={'#f2dbd3'}
          handleOnClick={() => handleGoToCreditAccounts('balance')}
          showAsNegative={creditTotals.totalCreditBalance > 0 ? true : false}
        />
      </div>
      <div className='mobile-widget-container'>
        <ClickableWidget
          title={'Credit Utilization'}
          amount={creditTotals.totalCreditUtilization}
          borderColor={'#9925be'}
          backgroundColor={'#ebd3f2'}
          handleOnClick={() => handleGoToCreditAccounts('used')}
          prefix={''}
          suffix={'%'}
        />
      </div>
    </div>
  )
}

export default Mobile;
