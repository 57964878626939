import { Navigate } from 'react-router-dom';

import { getToken, getUser } from '../../services/localStorageService';

const AnonymousRoute = ({
  children
}) => {

  const isAuthenticated = () => {
    const user = getUser();
    const token = getToken();

    if(token && user) {
      const parsedToken = JSON.parse(window.atob(token.split(".")[1]));
      if(parsedToken.exp * 1000 > Date.now()) {
        return true;
      }
    }
    return false;
  }

  if(isAuthenticated()) {
    return  (
      <Navigate to='/' replace/>
    );
  }
  return children;
}

export default AnonymousRoute;
