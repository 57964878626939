import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Menu,
  MenuItem,
  SidebarContent,
} from 'react-pro-sidebar';
import { 
  FaTh, 
  FaDollarSign, 
  FaRegCreditCard, 
  FaShoppingCart, 
  FaChartPie, 
  FaUserEdit,
  FaUsers,
  FaEnvelope } from 'react-icons/fa';

import './nav.css';
import { getUser } from '../../../shared/services/localStorageService';
import { CurrentUserModel } from '../../../shared/models/CurrentUserModel';

const Nav = ({
  toggled, 
  handleToggleSidebar 
}) => {

  const [currentUser, setCurrentUser] = useState<CurrentUserModel>(new CurrentUserModel());
  
  useEffect(() => {
    const user = getUser();
    setCurrentUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='sidebar-nav-container'>
      <SidebarContent>
        <Menu>
          <MenuItem onClick={() => handleToggleSidebar(!toggled)} icon={<FaTh className='sidebar-nav-icon' color='black' />}>
            <NavLink  to='/' >
              Dashboard
            </NavLink> 
          </MenuItem>
          <hr />
          <MenuItem onClick={() => handleToggleSidebar(!toggled)} icon={<FaChartPie className='sidebar-nav-icon' color='#9925be' />}>
            <NavLink to='/budgets'>
              Budgets
            </NavLink> 
          </MenuItem>
{/*           <MenuItem onClick={() => handleToggleSidebar(!toggled)} icon={<FaChartLine className='sidebar-nav-icon' color='#9925be' />}>
            <NavLink to='/forecasts'>
              Forecasts
            </NavLink> 
          </MenuItem> */}
          <MenuItem onClick={() => handleToggleSidebar(!toggled)} icon={<FaRegCreditCard className='sidebar-nav-icon' color='#be4d25' />}>
            <NavLink to='/accounts'>
              Accounts
            </NavLink> 
          </MenuItem>
          <MenuItem onClick={() => handleToggleSidebar(!toggled)} icon={<FaDollarSign className='sidebar-nav-icon' color='#49be25' />}>
            <NavLink to='/income'>
              Income
            </NavLink> 
          </MenuItem>
          <MenuItem onClick={() => handleToggleSidebar(!toggled)} icon={<FaShoppingCart className='sidebar-nav-icon' color='#2596be' />}>
            <NavLink to='/merchants'>
              Merchants
            </NavLink> 
          </MenuItem>
          {currentUser.isAdmin && (
            <React.Fragment>
              <hr />
              <MenuItem onClick={() => handleToggleSidebar(!toggled)} icon={<FaUsers className='sidebar-nav-icon' color='black' />}>
                <NavLink to='/users'>
                  Users
                </NavLink> 
              </MenuItem>
              <MenuItem onClick={() => handleToggleSidebar(!toggled)} icon={<FaEnvelope className='sidebar-nav-icon' color='black' />}>
                <NavLink to='/invite-requests'>
                  Invite Requests
                </NavLink> 
              </MenuItem>
            </React.Fragment>
          )}
          <hr />
          <MenuItem onClick={() => handleToggleSidebar(!toggled)} icon={<FaUserEdit className='sidebar-nav-icon' color='black' />}>
            <NavLink to='/my-account'>
              My Account
            </NavLink> 
          </MenuItem>
        </Menu>
      </SidebarContent>
    </div>
  );
}

export default Nav;