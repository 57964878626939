import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { getUser, setUser } from './shared/services/localStorageService';
import { CurrentUserModel } from './shared/models/CurrentUserModel';
import NotFound from './auth/NotFound/NotFound';
import AnonymousRoute from './shared/components/AnonymousRoute/AnonymousRoute';
import ProtectedRoute from './shared/components/ProtectedRoute/ProtectedRoute';
import AdminProtectedRoute from './shared/components/AdminProtectedRoute/AdminProtectedRoute';
import SignIn from './auth/SignIn/SignIn';
import SignUp from './auth/SignUp/SignUp';
import ForgotPassword from './auth/ForgotPassword/ForgotPassword';
import ResetPassword from './auth/ResetPassword/ResetPassword';
import Layout from './structure/Layout/Layout';
import Dashboard from './features/Dashboard/Dashboard';
import ListAccounts from './features/Accounts/List/ListAccounts';
import ListCreditAccounts from './features/Accounts/List/ListCreditAccounts';
import CreateAccount from './features/Accounts/Create/CreateAccount';
import UpdateAccount from './features/Accounts/Update/UpdateAccount';
import ListAccountTransactions from './features/Accounts/Transactions/List/ListAccountTransactions';
import PayAccount from './features/Accounts/Transactions/Pay/PayAccount';
import PayFee from './features/Accounts/Transactions/Fee/PayFee';
import RecordCashBack from './features/Accounts/Transactions/CashBack/RecordCashBack';
import RecordInterest from './features/Accounts/Transactions/Interest/RecordInterest';
import TransferFunds from './features/Accounts/Transactions/Transfer/TransferFunds';
import UpdateAccountPayment from './features/Accounts/Transactions/Update/UpdateAccountPayment';
import ListBudgets from './features/Budgets/List/ListBudgets';
import CreateBudget from './features/Budgets/Create/CreateBudget';
import UpdateBudget from './features/Budgets/Update/UpdateBudget';
import ManageBudget from './features/Budgets/Manage/ManageBudget';
import ListIncome from './features/Income/List/ListIncome';
import CreateIncome from './features/Income/Create/CreateIncome';
import UpdateIncome from './features/Income/Update/UpdateIncome';
import ListIncomeTransactions from './features/Income/Transactions/List/ListIncomeTransactions';
import PayIncome from './features/Income/Transactions/Pay/PayIncome';
import UpdateIncomePayment from './features/Income/Transactions/Update/UpdateIncomePayment';
import ListMerchants from './features/Merchants/List/ListMerchants';
import CreateMerchant from './features/Merchants/Create/CreateMerchant';
import UpdateMerchant from './features/Merchants/Update/UpdateMerchant';
import ListMerchantTransactions from './features/Merchants/Transactions/List/ListMerchantTransactions';
import PayMerchant from './features/Merchants/Transactions/Pay/PayMerchant';
import UpdateMerchantPayment from './features/Merchants/Transactions/Update/UpdateMerchantPayment';
import ManageUsers from './features/Users/Manage/ManageUsers';
import UpdateUser from './features/Users/Update/UpdateUser';
import RequestInvite from './features/InviteRequests/RequestInvite/RequestInvite';
import ValidateInvite from './features/InviteRequests/ValidateInvite/ValidateInvite';
import ManageInvites from './features/InviteRequests/ManageInvites/ManageInvites';

export function App() {

  const [currentUser, setCurrentUser] = useState<CurrentUserModel>(new CurrentUserModel());

  const handleUpdateUser = (data: CurrentUserModel) => {
    const currentUser = getUser();
    data.isAdmin = currentUser.isAdmin;
    data.username = currentUser.username;
    setUser(data);
    setCurrentUser(data);
  }

  useEffect(() => {
    const user = getUser();
    setCurrentUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/sign-in' element={<AnonymousRoute><SignIn /></AnonymousRoute>} />
        <Route path='/sign-up' element={<AnonymousRoute><SignUp /></AnonymousRoute>} />
        <Route path='/request-invite' element={<AnonymousRoute><RequestInvite /></AnonymousRoute>} />
        <Route path='/validate-invite' element={<AnonymousRoute><ValidateInvite /></AnonymousRoute>} />
        <Route path='/forgot-password' element={<AnonymousRoute><ForgotPassword /></AnonymousRoute>} />
        <Route path='/reset-password' element={<AnonymousRoute><ResetPassword /></AnonymousRoute>} />
        <Route path='/' element={<ProtectedRoute><Layout currentUser={currentUser} setCurrentUser={setCurrentUser} /></ProtectedRoute>}>
          <Route index element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path='/quick-action/pay' element={<ProtectedRoute><PayAccount /></ProtectedRoute>} />
          <Route path='/quick-action/buy' element={<ProtectedRoute><PayMerchant /></ProtectedRoute>} />
          <Route path='/quick-action/receive' element={<ProtectedRoute><PayIncome /></ProtectedRoute>} />
          <Route path='/dashboard' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path='/accounts' element={<ProtectedRoute><ListAccounts /></ProtectedRoute>} />
          <Route path='/accounts/create' element={<ProtectedRoute><CreateAccount /></ProtectedRoute>} />
          <Route path='/accounts/credit/:showColumn' element={<ProtectedRoute><ListCreditAccounts /></ProtectedRoute>} />
          <Route path='/accounts/primary' element={<ProtectedRoute><CreateAccount isPrimary={true} /></ProtectedRoute>} />
          <Route path='/accounts/:accountId' element={<ProtectedRoute><UpdateAccount /></ProtectedRoute>} />
          <Route path='/accounts/:accountId/transactions' element={<ProtectedRoute><ListAccountTransactions /></ProtectedRoute>} />
          <Route path='/accounts/:accountId/transactions/pay' element={<ProtectedRoute><PayAccount /></ProtectedRoute>} />
          <Route path='/accounts/:accountId/transactions/fee' element={<ProtectedRoute><PayFee /></ProtectedRoute>} />
          <Route path='/accounts/:accountId/transactions/cash-back' element={<ProtectedRoute><RecordCashBack /></ProtectedRoute>} />
          <Route path='/accounts/:accountId/transactions/interest' element={<ProtectedRoute><RecordInterest /></ProtectedRoute>} />
          <Route path='/accounts/:accountId/transactions/transfer' element={<ProtectedRoute><TransferFunds /></ProtectedRoute>} />
          <Route path='/accounts/:accountId/transactions/:transactionId' element={<ProtectedRoute><UpdateAccountPayment /></ProtectedRoute>} />
          <Route path='/budgets' element={<ProtectedRoute><ListBudgets /></ProtectedRoute>} />
          <Route path='/budgets/create' element={<ProtectedRoute><CreateBudget /></ProtectedRoute>} />
          <Route path='/budgets/:budgetId' element={<ProtectedRoute><UpdateBudget /></ProtectedRoute>} />
          <Route path='/budgets/:budgetId/manage' element={<ProtectedRoute><ManageBudget /></ProtectedRoute>} />
          <Route path='/budgets/:budgetId/budget-items/:budgetItemId/pay-account/:accountId' element={<ProtectedRoute><PayAccount /></ProtectedRoute>} />
          <Route path='/budgets/:budgetId/budget-items/:budgetItemId/pay-merchant/:accountId' element={<ProtectedRoute><PayMerchant /></ProtectedRoute>} />
          <Route path='/budgets/:budgetId/budget-items/:budgetItemId/receive-income/:accountId' element={<ProtectedRoute><PayIncome /></ProtectedRoute>} />
          <Route path='/income' element={<ProtectedRoute><ListIncome /></ProtectedRoute>} />
          <Route path='/income/create' element={<ProtectedRoute><CreateIncome /></ProtectedRoute>} />
          <Route path='/income/:incomeId' element={<ProtectedRoute><UpdateIncome /></ProtectedRoute>} />
          <Route path='/income/:incomeId/transactions' element={<ProtectedRoute><ListIncomeTransactions /></ProtectedRoute>} />
          <Route path='/income/:incomeId/transactions/pay' element={<ProtectedRoute><PayIncome /></ProtectedRoute>} />
          <Route path='/income/:accountId/transactions/receive' element={<ProtectedRoute><PayIncome /></ProtectedRoute>} />
          <Route path='/income/:incomeId/transactions/:transactionId' element={<ProtectedRoute><UpdateIncomePayment /></ProtectedRoute>} />
          <Route path='/merchants' element={<ProtectedRoute><ListMerchants /></ProtectedRoute>} />
          <Route path='/merchants/create' element={<ProtectedRoute><CreateMerchant /></ProtectedRoute>} />
          <Route path='/merchants/:merchantId' element={<ProtectedRoute><UpdateMerchant /></ProtectedRoute>} />
          <Route path='/merchants/:merchantId/transactions' element={<ProtectedRoute><ListMerchantTransactions /></ProtectedRoute>} />
          <Route path='/merchants/:accountId/transactions/pay' element={<ProtectedRoute><PayMerchant /></ProtectedRoute>} />
          <Route path='/merchants/:merchantId/transactions/receive' element={<ProtectedRoute><PayMerchant /></ProtectedRoute>} />
          <Route path='/merchants/:merchantId/transactions/:transactionId' element={<ProtectedRoute><UpdateMerchantPayment /></ProtectedRoute>} />
          <Route path='/my-account' element={<ProtectedRoute><UpdateUser handleUpdateUser={handleUpdateUser} /></ProtectedRoute>} />
          <Route path='/users' element={<AdminProtectedRoute><ManageUsers /></AdminProtectedRoute>} />
          <Route path='/invite-requests' element={<AdminProtectedRoute><ManageInvites /></AdminProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}